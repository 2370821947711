import axios from "axios";
import {
	Button,
	Column,
	DatePicker,
	Error,
	FormRow,
	Input,
	Modal,
	SelectorSearch,
	Table,
} from "globalComponents";
import { errorHandler } from "globalResources/util";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { getTheme } from "Theme";
import { timesheetEditHeaders, timesheetHeaders } from "./headers";
const theme = getTheme();

const Main = styled.div`
	padding: 10px 20px;
	display: grid;
	grid-template-columns: 1fr 3fr;
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${theme.SECONDARY_COLOR};
	padding: 20px;
	border-radius: 10px;
`;

const ListContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
`;

const Title = styled.h2``;
const RateEditor = () => {
	const [form, setForm] = useState({
		client: null,
		user: null,
		start: null,
		end: null,
		billRate: 0,
		payRate: 0,
		overtimeBillRate: 0,
		overtimePayRate: 0,
		position: "employee",
	});
	const [search, setSearch] = useState({
		client: "",
		user: "",
	});
	const [userSuggestions, setUserSuggestions] = useState([]);
	const [clientSuggestions, setClientSuggestions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [timesheets, setTimesheets] = useState([]);
	const [errors, setErrors] = useState({ general: "" });
	const [check, setCheck] = useState(false);
	const history = useHistory();

	useEffect(() => {
		fetchClients();
		fetchUsers();
	}, []);

	useEffect(() => fetchUsers(), [search.user, form.client]);
	useEffect(() => {
		fetchClients();
	}, [search.user, form.user]);

	useEffect(() => {
		if (!form.user)
			setErrors(old => ({ ...old, general: "Select a user first" }));
		else fetchTimesheets();
	}, [form]);

	const fetchClients = () => {
		axios
			.get(`/client/suggestions`)
			.then(async res => {
				var clients = res.data;
				var temp = [];
				clients.forEach(client => {
					if (client.name !== "All")
						temp.push({ label: client.name, value: client._id });
				});
				setClientSuggestions(temp);
			})
			.catch(async err => {
				const { message } = await errorHandler(err);
			});
	};

	const fetchUsers = () => {
		axios
			.get(`/users`, {
				params: {
					search: search.user,
					searchBy: "name",
					client: form.client,
					limit: 100,
					accountType: "employee",
				},
			})
			.then(async res => {
				const users = res.data.users;
				var temp = [];

				users.forEach(user => {
					temp.push({
						label: `${user.firstName} ${user.lastName}`,
						value: user._id,
					});
				});
				temp.sort((a, b) => (a.label > b.label ? 1 : -1));
				setUserSuggestions(temp);
			})
			.catch(async err => {
				const { message } = await errorHandler(err);
			});
	};

	const fetchTimesheets = () => {
		setLoading(true);
		axios
			.get(`/timesheets`, {
				params: {
					user: form.user,
					start:
						form.start && moment(form.start, "YYYY-MM-DD").startOf("day").utc(),
					end: form.end && moment(form.end, "YYYY-MM-DD").endOf("day").utc(),
					client: form.client,
					limit: 100,
					billed: false,
				},
			})
			.then(async res => {
				setTimesheets(res.data.timesheets);
			})
			.catch(async err => {
				const { message } = await errorHandler(err);
			})
			.finally(() => setLoading(false));
	};

	const updateSheets = () => {
		setLoading(true);
		axios
			.patch(`/timesheets/update-rates`, {
				sheets: timesheets,
				billRate: form.billRate * 100,
				overtimeBillRate: form.overtimeBillRate * 100,
				payRate: form.payRate * 100,
				overtimePayRate: form.overtimePayRate * 100,
				position: form.position,
			})
			.then(async res => {
				fetchTimesheets();
			})
			.catch(async err => {
				const { message } = await errorHandler(err);
			})
			.finally(() => setLoading(false));
	};

	return (
		<Main>
			<FormContainer>
				<FormRow style={{ justifyContent: "space-between" }}>
					<Title>Rate Edit Form</Title>
					<p>*only non-billed timesheets are editable</p>
				</FormRow>
				<SelectorSearch
					onSelect={user => setForm(old => ({ ...old, user }))}
					onSearch={v => setSearch(old => ({ ...old, user: v }))}
					label="Employee"
					options={userSuggestions}
					width="100%"
					value={form.user}
				/>
				<SelectorSearch
					onSelect={client => setForm(old => ({ ...old, client }))}
					onSearch={v => setSearch(old => ({ ...old, client: v }))}
					label="Client (optional)"
					options={clientSuggestions}
					width="100%"
					value={form.client}
				/>
				<FormRow>
					<DatePicker
						type="date"
						label="Start"
						width="100%"
						max={moment(form.end).format("YYYY-MM-DD")}
						onChange={v => {
							setForm(old => ({ ...old, start: v }));
						}}
						onFirst={v =>
							!form.end &&
							setForm(old => ({
								...old,
								end: moment(v).add(6, "days").format("YYYY-MM-DD"),
							}))
						}
						value={moment(form.start).format("YYYY-MM-DD")}
					/>
					<DatePicker
						type="date"
						label="End"
						width="100%"
						min={moment(form.start).format("YYYY-MM-DD")}
						onChange={v => setForm(old => ({ ...old, end: v }))}
						value={moment(form.end).format("YYYY-MM-DD")}
					/>
				</FormRow>
				<Input
					label="Position"
					value={form.position}
					onChange={e => setForm(old => ({ ...old, position: e.target.value }))}
				/>
				<FormRow>
					<Input
						label="Pay Rate"
						type="currency"
						value={form.payRate}
						onChange={e => {
							const v = parseFloat(e.target.value);
							setForm(old => ({
								...old,
								payRate: v,
							}));
						}}
						onBlur={() => {
							setForm(old => ({
								...old,
								payRate: parseFloat(old.payRate).toFixed(2),
								overtimePayRate: (parseFloat(old.payRate) * 1.5).toFixed(2),
							}));
						}}
					/>
					<Input
						label="Bill Rate"
						type="currency"
						value={form.billRate}
						onChange={e => {
							const v = parseFloat(e.target.value);
							setForm(old => ({
								...old,
								billRate: v,
							}));
						}}
						onBlur={() =>
							setForm(old => ({
								...old,
								billRate: parseFloat(old.billRate).toFixed(2),
								overtimeBillRate: (parseFloat(old.billRate) * 1.5).toFixed(2),
							}))
						}
					/>
				</FormRow>
				<FormRow>
					<Input
						label="Overtime Pay Rate"
						type="currency"
						value={form.overtimePayRate}
						onChange={e => {
							const v = parseFloat(e.target.value);
							setForm(old => ({ ...old, overtimePayRate: v }));
						}}
						onBlur={() =>
							setForm(old => ({
								...old,
								overtimePayRate: parseFloat(old.overtimePayRate).toFixed(2),
							}))
						}
					/>
					<Input
						label="Overtime Bill Rate"
						type="currency"
						value={form.overtimeBillRate}
						onChange={e => {
							const v = parseFloat(e.target.value);
							setForm(old => ({ ...old, overtimeBillRate: v }));
						}}
						onBlur={() =>
							setForm(old => ({
								...old,
								overtimeBillRate: parseFloat(old.overtimeBillRate).toFixed(2),
							}))
						}
					/>
				</FormRow>
				<FormRow style={{ marginTop: "auto" }}>
					<Button
						color={theme.BUTTON_COLOR_GREY}
						onClick={() => history.push("/timesheets")}
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							if (timesheets.length > 0) setCheck(true);
							else
								setErrors(old => ({
									...old,
									general: "No timesheets to update",
								}));
						}}
					>
						Update Rates
					</Button>
				</FormRow>
			</FormContainer>
			<ListContainer>
				<Title>All Time Entries shown will be updated</Title>

				<Error>{errors.general}</Error>
				<Table
					data={timesheets}
					loading={loading}
					headers={timesheetEditHeaders}
				/>
			</ListContainer>
			{check && (
				<Modal onClose={() => setCheck(false)}>
					<Column>
						<Title>
							Are you sure you want to update {timesheets.length} time entries?
						</Title>
						<FormRow style={{ marginTop: "auto", width: "100%" }}>
							<Button
								color={theme.BUTTON_COLOR_GREY}
								onClick={() => setCheck(false)}
							>
								Cancel
							</Button>
							<Button
								onClick={() => {
									setCheck(false);
									updateSheets();
								}}
							>
								Update Rates
							</Button>
						</FormRow>
					</Column>
				</Modal>
			)}
		</Main>
	);
};
export default RateEditor;
