import React from "react";
import {
	Button,
	Form,
	Modal,
	Header,
	Grid,
	TextArea,
	Checkbox,
} from "semantic-ui-react";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Theme from "../Theme.js";
import cookie from "js-cookie";
import { connect } from "react-redux";

const SignupSchema = Yup.object().shape({
	user: Yup.string().required("Required"),
});

class AddAdjustment extends React.Component {
	state = {
		users: [],
		open: false,
		customPayRate: false,
		customBillRate: false,
	};

	fetchUsers = () => {
		this.setState({ loading: true });
		axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
			"token"
		)}`;
		axios
			.get(
				cookie.get("type") == "client"
					? `${process.env.REACT_APP_API_URL}/client/team`
					: `${process.env.REACT_APP_API_URL}/users`,
				{
					params:
						cookie.get("type") == "client"
							? {}
							: {
									client: this.props.report.client._id,
									search: this.state.search,
									searchBy: "name",
							  },
				}
			)
			.then(async res => {
				{
					const users = res.data.users;
					var temp = [];
					users.forEach(user => {
						if (cookie.get("type") == "client")
							temp.push({
								text: `${user.user.firstName} ${user.user.lastName}`,
								value: user.user._id,
							});
						else
							temp.push({
								text: `${user.firstName} ${user.lastName}`,
								value: user._id,
							});
					});
					this.setState({
						users: temp,
					});
				}
			})
			.catch(err => this.setState({ loading: false, error: "error" }));
	};

	deleteAdjustment = () => {
		axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
			"token"
		)}`;
		this.setState({ loading: true });
		axios
			.delete(`${process.env.REACT_APP_API_URL}/report/adjustment`, {
				params: {
					id: this.props.edit._id,
					reportId: this.props.report._id,
				},
			})
			.then(async res => {
				this.props.onClose();
			})
			.catch(err => this.setState({ loading: false, error: "error" }));
	};
	componentDidMount() {
		this.fetchUsers();
	}
	render() {
		const { users, open } = this.state;
		const { report, edit, onClose } = this.props;
		if (!edit) return null;
		return (
			<Modal
				open={!!edit}
				centered
				closeIcon
				onOpen={() => this.setState({ open: true })}
				onClose={onClose}
				textAlign="center"
				style={{
					textAlign: "center",
					backgroundColor: "white",
					padding: "2vh",
					maxWidth: 600,
				}}
				verticalAlign="middle"
			>
				<Header as="h1">
					{edit._id ? "Edit Adjustment" : "Adding Adjustment"}{" "}
				</Header>
				<Formik
					initialValues={
						edit.user
							? {
									amount: edit.amount / 100,
									notes: edit.notes,
									user: edit.user._id,
									bonus: edit.bonus,
							  }
							: {
									amount: "",
									notes: "",
									user: "",
									bonus: cookie.get("type") === "client" ? true : false,
							  }
					}
					enableReinitialize={true}
					validationSchema={SignupSchema}
					onSubmit={(values, actions) => {
						actions.setSubmitting(true);
						axios[edit._id ? "patch" : "post"](
							`${process.env.REACT_APP_API_URL}/report/adjustments`,
							{
								reportId: report._id,
								id: edit._id,
								user: values.user,
								notes: values.notes,
								amount: values.amount * 100,
								hours: values.hours,
								bonus: values.bonus,
							}
						)
							.then(res => {
								this.setState({ open: false });
								this.props.onClose(res.data.report);
							})
							.catch(error =>
								actions.setFieldError("general", "error creating adjustment")
							)
							.finally(() => actions.setSubmitting(false));
					}}
					render={props => (
						<Form onSubmit={props.handleSubmit} size="large">
							<Grid
								style={{
									borderRadius: 10,
									backgroundColor: Theme.PAGE_BACKGROUND,
									padding: "1%",
								}}
							>
								{cookie.get("type") !== "client" && (
									<Grid.Row style={{ justifyContent: "center" }}>
										<Header
											as="h3"
											style={{
												color: "white",
												margin: "auto 5px",
											}}
										>
											Bonus
										</Header>
										<Checkbox
											checked={props.values.bonus}
											toggle
											style={{
												textColor: "white",
												color: "red",
											}}
											onChange={() =>
												props.setFieldValue("bonus", !props.values.bonus)
											}
										/>
									</Grid.Row>
								)}
								<Grid.Row>
									<Grid.Column centered>
										<Header as="h3" style={{ color: "white", margin: "auto" }}>
											User
										</Header>
										<Form.Dropdown
											onChange={(e, v) => props.setFieldValue("user", v.value)}
											selection
											search
											onSearchChange={v =>
												this.setState({ search: v.value }, this.fetchUsers)
											}
											value={props.values.user}
											options={users}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={2}>
									<Grid.Column>
										<Header as="h3" style={{ color: "white", margin: "auto" }}>
											Amount
										</Header>
										<Form.Input
											name="amount"
											onBlur={() =>
												props.setFieldValue(
													"amount",
													!props.values.amount
														? 0
														: parseFloat(props.values.amount).toFixed(2)
												)
											}
											value={props.values.amount}
											onChange={(e, v) =>
												props.setFieldValue("amount", v.value)
											}
										/>
									</Grid.Column>
									<Grid.Column>
										<Header as="h3" style={{ color: "white", margin: "auto" }}>
											Hours
										</Header>
										<Form.Input
											name="hours"
											onBlur={() =>
												props.setFieldValue(
													"hours",
													!props.values.hours
														? 0
														: parseFloat(props.values.hours).toFixed(2)
												)
											}
											value={props.values.hours}
											onChange={(e, v) => props.setFieldValue("hours", v.value)}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row style={{ padding: "2%" }}>
									<Header as="h3" style={{ color: "white", margin: "auto" }}>
										Notes
									</Header>
									<TextArea
										value={props.values.notes}
										onChange={(e, d) => props.setFieldValue("notes", d.value)}
									/>
								</Grid.Row>
								<Grid.Row>
									{props.errors.general && (
										<div
											style={{
												color: "blue",
												margin: "-1vh auto .5vh auto",
												textAlign: "center",
											}}
										>
											{props.errors.general}
										</div>
									)}

									<div
										style={{
											display: "flex",
											flexDirection: "row",
											width: "100%",
										}}
									>
										<Button
											style={{ margin: "auto " }}
											fluid
											disabled={props.isSubmitting}
											onClick={this.deleteAdjustment}
											type="button"
											color="red"
										>
											Delete Adjustment
										</Button>
										<Button
											style={{ margin: "auto 5px" }}
											fluid
											disabled={props.isSubmitting}
											color="blue"
											type="submit"
										>
											{edit.user ? "Edit Adjustment" : "Add Adjustment"}
										</Button>
									</div>
								</Grid.Row>
							</Grid>
						</Form>
					)}
				/>
			</Modal>
		);
	}
}

const mapStateToProps = state => ({ ...state.global });
export default connect(mapStateToProps, null)(AddAdjustment);
