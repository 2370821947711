import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useClickOutside from "hooks/useClickOutside";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
import { ReactComponent as ClearIcon } from "../assets/tcs-clear.svg";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${(props) => props.width || "100%"};
  min-width: 100px;
`;
const Option = styled.div`
  line-height: 15px;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px;
  :hover {
    background-color: ${theme.INPUT_BORDER};
  }
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 4px;
`;

const InputContainer = styled.div`
  display: flex;
  border: 2px solid
    ${(props) => (props.error ? theme.NEGATIVE : theme.INPUT_BORDER)};
  background: ${theme.INPUT_BACKGROUND};
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 10px;
  user-select: auto;
  border: 2px solid
    ${(props) => (props.error ? theme.NEGATIVE : theme.INPUT_BORDER)};
`;

const Input = styled.input`
  border: none;
  outline: none;
  background: none;
  width: 100%;
  color: ${theme.INPUT_TEXT};
`;

const Clear = styled(ClearIcon)`
  width: auto;
  height: 20px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

const OptionList = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")};
  position: absolute;
  top: 93%;
  flex-direction: column;
  background-color: white;
  padding: 4px;
  gap: 6px;
  z-index: 10;
  width: 100%;
  border-radius: 5px;
  border: 2px solid ${theme.INPUT_BORDER};
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  max-height: 400px;
  overflow-y: auto;
`;

const Error = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${theme.NEGATIVE};
`;

const SelectorSearch = ({
  options = [],
  width = "",
  onSelect,
  label,
  value,
  onSearch,
  placeholder,
  fillSearch = true,
  error,
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  let ref = useRef(null);

  const shouldFilter = (v) => {
    if (value) return false;
    if (search && !v.toLowerCase().includes(search.toLowerCase())) return true;
    else return false;
  };

  useClickOutside(ref, () => setOpen(false));
  useEffect(() => {
    if (value && fillSearch) {
      let i = options.find((i) => i.value == value);
      i && setSearch(i.label);
    }
  }, [value, options]);

  return (
    <Main id="selector" ref={ref} width={width}>
      <Label for="selection"> {label}</Label>
      <InputContainer error={error}>
        <Input
          autoComplete="off"
          onFocus={() => setOpen(true)}
          value={search}
          placeholder={placeholder}
          type="text"
          onChange={(e) => {
            onSearch && onSearch(e.currentTarget.value);
            setSearch(e.currentTarget.value);
            if (value) onSelect(null);
          }}
        />
        <Clear
          onClick={() => {
            onSelect(null);
            onSearch && onSearch(null);
            setSearch("");
          }}
        >
          x
        </Clear>
      </InputContainer>
      <Error>{error}</Error>
      <OptionList open={open}>
        {options.map(
          (option) =>
            !shouldFilter(option.label) && (
              <Option
                onClick={() => {
                  onSelect(option.value);
                  setOpen(false);
                }}
              >
                {option.label}
              </Option>
            )
        )}
      </OptionList>
    </Main>
  );
};
export default SelectorSearch;
