import { Header, Icon, Popup } from "semantic-ui-react";
import moment from "moment-timezone";
import { formatRate } from "globalResources/util";

export const timesheetHeaders = [
  {
    label: "Client",
    render: (row) => <div>{row.client && row.client.name}</div>,
  },
  {
    label: "User",
    render: (row) => <div>{row.user.firstName + " " + row.user.lastName}</div>,
  },
  {
    label: "Start Time",
    sortBy: "start",
    render: (row) => (
      <div>
        {row.start &&
          moment(row.start)
            .tz(row.client ? row.client.timezone : "US/Central")
            .format("dddd lll z")}
      </div>
    ),
  },
  {
    label: "End Time",
    sortBy: "end",
    render: (row) => (
      <div>
        {row.end &&
          moment(row.end)
            .tz(row.client ? row.client.timezone : "US/Central")
            .format("dddd lll z")}
      </div>
    ),
  },
  {
    label: "Position",
    width: "30%",
    render: (row) => <div>{row.position}</div>,
  },
  {
    label: "Hours",
    width: "20%",
    render: (row) => (
      <div>
        {(
          moment.duration(moment(row.end).diff(moment(row.start))).asHours() -
          (row.client.subtractLunch ? 0.5 : 0)
        ).toFixed(2)}
      </div>
    ),
  },
  {
    label: (
      <Popup
        position="top center"
        content={
          <div>
            <Icon name="file" color="green" /> = billed <br />
            <Icon name="checkmark" color="green" /> = reported <br />
            <Icon name="delete" color="blue" /> = errored <br />
            <Icon name="minus" color="grey" /> = new <br />
          </div>
        }
        trigger={<div>Status</div>}
      />
    ),
    render: (row) => (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {row.billed ? (
          <Icon color="green" name="file" />
        ) : row.errored ? (
          <Icon color="blue" name="delete" />
        ) : row.reported ? (
          <Icon color="green" name="checkmark" />
        ) : (
          <Icon color="grey" name="minus" />
        )}
      </div>
    ),
    width: "20%",
  },
  {
    label: (
      <Popup
        position="left center"
        content={
          <div>
            <Header>How the time was added</Header>
            <br />
            <Icon name="mail" color="purple" /> = SMS <br />
            <Icon name="male" color="blue" /> = Manager <br />
            <Icon name="desktop" color="yellow" /> = Time Station <br />
            <Icon name="server" color="red" /> = Auto Clock-Out <br />
            <Icon name="mobile" color="green" /> = Mobile App <br />
            <Icon name="location arrow" color="green" /> = Location verified{" "}
            <br />
            <Icon name="cancel" color="red" /> = Location not verified
          </div>
        }
        trigger={<div>Type</div>}
      />
    ),
    width: "20%",
    render: (row) => (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {row.type === "sms" || row.sms ? (
          <Icon color="purple" name="mail" />
        ) : row.type === "app" ? (
          <>
            <Icon color="green" name="mobile" />
            <Icon
              color={row.gps ? "green" : "red"}
              name={row.gps ? "location arrow" : "cancel"}
            />
          </>
        ) : row.type === "station" ? (
          <Icon color="yellow" name="desktop" />
        ) : row.type === "auto" ? (
          <Icon color="red" name="server" />
        ) : (
          <Icon color="blue" name="male" />
        )}
      </div>
    ),
  },
];

export const timesheetEditHeaders = [
  {
    label: "Client",
    render: (row) => <div>{row.client && row.client.name}</div>,
  },
  {
    label: "User",
    render: (row) => <div>{row.user.firstName + " " + row.user.lastName}</div>,
  },
  {
    label: "Start Time",
    sortBy: "start",
    render: (row) => (
      <div>
        {row.start &&
          moment(row.start)
            .tz(row.client ? row.client.timezone : "US/Central")
            .format("dddd lll z")}
      </div>
    ),
  },
  {
    label: "End Time",
    sortBy: "end",
    render: (row) => (
      <div>
        {row.end &&
          moment(row.end)
            .tz(row.client ? row.client.timezone : "US/Central")
            .format("dddd lll z")}
      </div>
    ),
  },
  {
    label: "Pay Rate",
    width: "30%",
    render: (row) => (
      <div>
        {formatRate(row.payRate)} ({formatRate(row.overtimePayRate)})
      </div>
    ),
  },
  {
    label: "Bill Rate",
    width: "30%",
    render: (row) => (
      <div>
        {formatRate(row.billRate)} ({formatRate(row.overtimeBillRate)})
      </div>
    ),
  },
  {
    label: "Position",
    width: "30%",
    render: (row) => <div>{row.position}</div>,
  },
  {
    label: (
      <Popup
        position="top center"
        content={
          <div>
            <Icon name="file" color="green" /> = billed <br />
            <Icon name="checkmark" color="green" /> = reported <br />
            <Icon name="delete" color="blue" /> = errored <br />
            <Icon name="minus" color="grey" /> = new <br />
          </div>
        }
        trigger={<div>Status</div>}
      />
    ),
    render: (row) => (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {row.billed ? (
          <Icon color="green" name="file" />
        ) : row.errored ? (
          <Icon color="blue" name="delete" />
        ) : row.reported ? (
          <Icon color="green" name="checkmark" />
        ) : (
          <Icon color="grey" name="minus" />
        )}
      </div>
    ),
    width: "20%",
  },
];
