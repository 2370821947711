import React from "react";
import Login from "./pages/user/Login.jsx";
import Home from "./pages/home/Home.jsx";
import SignUp from "./pages/user/SignUp.js";
import PasswordReset from "./pages/user/PasswordReset";
import NewPassword from "./pages/user/NewPassword";
import Users from "./pages/users";
import Groups from "./pages/groups";
import Timesheets from "./pages/timesheets/";
import Clients from "./pages/clients";
import Accounting from "./pages/accounting";
import Analytics from "./pages/analytics/Analytics.js";
import PhoneReset from "./pages/user/PhoneReset.js";
import TimeStation from "./pages/timesheets/TimeStation.js";
import Invoice from "./pages/invoice";
import Logout from "pages/home/Logout.js";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import cookie from "js-cookie";
import axios from "axios";
import { clearCookies } from "globalResources/helpers/index.js";
import { NavigationBar } from "./globalComponents";
import { setUser } from "store/slices/userSlice.js";
import HandleLoginLink from "pages/user/HandleLoginLink.jsx";
import BatchEntries from "pages/timesheets/BatchEntries.js";
import ExternalTimeStation from "pages/external/ExternalTimeStation.jsx";
import ClientDetails from "pages/clients/ClientDetails.jsx";
import RateEditor from "pages/timesheets/components/RateEditor.jsx";

class App extends React.Component {
  render() {
    let token = cookie.get("token");
    if (!token) {
      clearCookies(true);
      setUser({});
    }

    axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
      "token"
    )}`;

    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    return (
      <Router>
        {token && <NavigationBar />}
        {token ? (
          <Switch>
            <Route path="/invoice" component={Invoice} />
            <Route exact path="/home" component={Home} />
            <Route path="/users" component={Users} />
            <Route path="/groups" component={Groups} />
            <Route exact path="/timesheets" component={Timesheets} />
            <Route path="/clients" component={Clients} />
            <Route path="/client/:id/details" component={ClientDetails} />
            <Route path="/accounting" component={Accounting} />
            <Route path="/analytics" component={Analytics} />
            <Route path="/timesheets/timestation" component={TimeStation} />
            <Route path="/timesheets/batch" component={BatchEntries} />
            <Route path="/timesheets/rate-editor" component={RateEditor} />
            <Route path="/logout" component={Logout} />
            <Redirect to="/home" />
          </Switch>
        ) : (
          <Switch>
            <Route path="/invoice" component={Invoice} />
            <Route path="/login" exact component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/password" component={PasswordReset} />
            <Route path="/phoneReset" component={PhoneReset} />
            <Route path="/user/password" component={NewPassword} />
            <Route path="/login/link/:token" component={HandleLoginLink} />
            <Route path="/timestation" component={ExternalTimeStation} />
            <Redirect to="/login" />
            {
              // if we dont have a token and they aren't on onboarding or login we //
              // redirect them to login
            }
          </Switch>
        )}
      </Router>
    );
  }
}

export default App;
