import { FolderContainer } from "globalComponents";
import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import ReportList from "./components/ReportList";
import ViewReport from "./components/ViewReport";
import { calculateReportTotals } from "globalResources/helpers/reports";
import moment from "moment-timezone";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

const Accounting = () => {
  const [state, setState] = useState({
    report: null,
    totals: [],
    groups: [],
    timesheets: [],
  });

  function fetchReport(row, limit, page) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/report?id=${row._id}`, {
        params: {
          page: page || state.page,
          limit: limit || state.limit,
        },
      })
      .then((res) => {
        calculateReportTotals(res.data.report, (ans) =>
          setState((old) => ({ ...old, ...ans }))
        );
      })
      .catch((err) => console.error(err));
  }

  return (
    <FolderContainer
      title={
        !state.report
          ? "Reports"
          : ` ${moment(state.report.start)
              .tz(state.report.client.timezone)
              .format("ll")} - ${moment(state.report.end)
              .tz(state.report.client.timezone)
              .format("ll")}`
      }
    >
      <Main>
        {!state.report ? (
          <ReportList onView={(row) => fetchReport(row)} />
        ) : (
          <ViewReport
            onBack={() => {
              setState((state) => ({ ...state, report: null }));
            }}
            report={state.report}
            groups={state.groups}
            totals={state.totals}
            refresh={(limit, page) => fetchReport(state.report, limit, page)}
          />
        )}
      </Main>
    </FolderContainer>
  );
};
export default Accounting;
