import { StandardTable } from "globalComponents";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import TimerManager from "./TimerManager";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "store/slices/userSlice";
import styled from "styled-components";
import { getTheme } from "Theme";
import NotesModal from "globalComponents/NotesModal";
const theme = getTheme();

const List = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 10px auto;
  height: 700px;
  background-image: linear-gradient(
    ${theme.SECONDARY_COLOR} 80%,
    transparent 102%
  );
  overflow-y: auto;
  padding: 4px 4px 8px 4px;
  @media (max-width: 900px) {
    height: 400px;
  }
`;
const TimeEntry = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px;
  margin: 1px 0 2px 0;
  align-items: center;
  font-size: 12px;
  background-color: white;
  font-weight: 800;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const Data = styled.div`
  font-size: 12px;
  width: 30%;
  @media (max-width: 900px) {
    font-size: 10px;
  }
`;

const UserClockins = () => {
  const timesheets = useSelector((state) => state.current.timesheets);
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`/user/current`, { params: { includeTimesheets: true } })
      .then((res) => {
        let permissions = res.data.permissions.map((perm) => perm.name);
        localStorage.setItem("permissions", JSON.stringify(permissions));
        dispatch(setUser({ ...res.data }));
      })
      .catch((err) => {
        console.error("current error: ", err);
      });
  }, []);

  return (
    <>
      <Grid.Column
        style={{
          maxWidth: "100vw",
          padding: "10px 10px",
        }}
      >
        <TimerManager update={() => console.log()} />
        <List>
          {timesheets.map((time) => (
            <TimeEntry onClick={() => setSelected(time)}>
              <Data>{time.client && time.client.name}</Data>
              <Data>{moment(time.start).format("dddd MM/DD")}</Data>
              <Data>
                {moment(time.start).format("hh:mm A ") +
                  moment(time.end).format("- hh:mm A")}
              </Data>
            </TimeEntry>
          ))}
        </List>
      </Grid.Column>
      {selected && (
        <NotesModal
          noButton={true}
          notes={selected.notes}
          type="timesheet"
          id={selected._id}
          onClose={() => setSelected(null)}
        />
      )}
    </>
  );
};

export default UserClockins;
