import React from "react";
import {
  Button,
  Grid,
  Image,
  Message,
  Modal,
  TextArea,
  Header,
} from "semantic-ui-react";
import axios from "axios";
import Logo from "../assets/logos/full.png";
import cookie from "js-cookie";
import Theme from "../Theme.js";

class BugReport extends React.Component {
  state = {
    error: null,
    loading: false,
    message: "",
    issue: "",
  };
  submit = () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
      "token"
    )}`;
    this.setState({ loading: true, message: "", error: "" });
    if (!this.state.issue)
      this.setState({ error: "Please describe the issue", loading: false });
    else
      axios
        .post(`${process.env.REACT_APP_API_URL}/user/bugReport`, {
          issue: this.state.issue,
        })
        .then(async (res) => {
          this.setState({
            message: "Bug report sent. Thank you.",
            loading: false,
          });
        })
        .catch((error) =>
          this.setState({ loading: false, error: "Error sending bug report" })
        );
  };
  render() {
    const { open, onClose, data } = this.props;
    const { error, loading, message } = this.state;
    return (
      <div>
        <Modal
          open={open}
          onClose={onClose}
          closeIcon
          style={{
            textAlign: "center",
            padding: "1vh",
            maxWidth: 450,
          }}
        >
          <Grid centered textAlign="center" verticalAlign="middle">
            <Grid.Column
              style={{
                borderRadius: 10,
                boxShadow: "0 0 30px black",
                textAlign: "center",
              }}
            >
              <Image
                style={{
                  margin: "0vh auto 1vh auto",
                  height: 150,
                }}
                src={Logo}
              />
              <Message style={{ backgroundColor: Theme.PAGE_BACKGROUND }}>
                <Header style={{ color: error ? "red" : "white" }}>
                  {error || message
                    ? error || message
                    : "Please describe your issue below"}
                </Header>
                {!message ? (
                  <TextArea
                    style={{ width: "100%", height: "15vw" }}
                    placeholder="Also, Please list the steps that led to your issue in detail"
                    onChange={(event, { value }) =>
                      this.setState({ issue: value })
                    }
                  />
                ) : null}
                <Button
                  disabled={loading}
                  loading={loading}
                  type="submit"
                  color="blue"
                  fluid
                  size="large"
                  onClick={
                    message
                      ? () => {
                          this.setState({ message: "" }, onClose);
                        }
                      : this.submit
                  }
                >
                  {message ? "Exit" : "Send Bug Report"}
                </Button>
              </Message>
            </Grid.Column>
          </Grid>
        </Modal>
      </div>
    );
  }
}

export default BugReport;
