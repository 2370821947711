import axios from "axios";
import { SelectorSearch } from "globalComponents";
import { clearCookies } from "globalResources/helpers";
import { errorHandler } from "globalResources/util";
import jsCookie from "js-cookie";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Modal } from "semantic-ui-react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 6px;
`;
const Title = styled.h2``;
const Message = styled.h4`
  margin-top: 0;
`;
const TimeStationModal = ({ open, onClose }) => {
  const [clientSuggestions, setClientSuggestions] = useState([]);
  const [client, setClient] = useState(null);
  const history = useHistory();

  const fetchClients = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/client/suggestions`)
      .then(async (res) => {
        var clients = res.data;
        var temp = [];
        clients.forEach((client) => {
          if (client.name !== "All")
            temp.push({ label: client.name, value: client._id });
        });
        setClientSuggestions(temp);
      })
      .catch((err) => {
        const { message } = errorHandler(err);
      });
  };

  const onSubmit = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/timestation/token`, {
        params: { client },
      })
      .then(async (res) => {
        await clearCookies();
        await jsCookie.set("timestationToken", res.data.token, {
          expires: 10000,
        });
        await jsCookie.set("timestationClient", client, { expires: 10000 });
        window.location.replace("/timestation");
      });
  };

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <Main>
        <Title>Timestation Setup</Title>
        <Message>
          Starting a time station on this computer will log you out to keep
          employees from accessing your account, but will make this browser able
          to be a time station from here on. After submitting below simply go to
          https://app.timecardsolutions.com/timestation to access the time
          station at any time. (we recommend bookmarking it)
        </Message>
        <Message>
          You can always change the time station by logging in again and setting
          up the time station with a different client.
        </Message>
        <SelectorSearch
          onSelect={(client) => setClient(client)}
          label="Client"
          options={clientSuggestions}
          width="60%"
          value={client}
        />
        <Button style={{ width: "250px" }} color="blue" onClick={onSubmit}>
          Setup Time Station
        </Button>
      </Main>
    </Modal>
  );
};
export default TimeStationModal;
