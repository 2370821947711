import React, { useEffect, useState } from "react";
import { Button, Icon, Header, Modal, Popup } from "semantic-ui-react";
import {
  SelectorSearch,
  Selector,
  Table,
  DatePicker,
} from "../../../globalComponents";
import axios from "axios";
import cookie from "js-cookie";
import Report from "../forms/Report";
import moment from "moment-timezone";
import { connect } from "react-redux";
import Theme from "../../../Theme.js";
import { errorHandler } from "globalResources/util";
import styled from "styled-components";
import CheckModal from "./CheckModal";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 10px 10px 10px;
  gap: 10px;
`;

const ReportList = ({ onView, user }) => {
  const [loading, setLoading] = useState(false);
  const [clientSuggestions, setClientSuggestions] = useState([]);
  const [filters, setFilters] = useState({ client: "", status: "all" });
  const [daterange, setDaterange] = useState({ start: "", end: "" });
  const [pagination, setPagination] = useState({
    limit: 20,
    page: 1,
    total: 0,
  });
  const [sort, setSort] = useState({ sort: "end", direction: -1 });
  const [selected, setSelected] = useState(null);
  const [reports, setReports] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [addCheck, setAddCheck] = useState(null);

  const unBill = async (id) => {
    await axios.post("/report/unbill", { reportId: id });
  };

  const headers = [
    {
      label: "Client",
      render: (row) => <div>{row.client.name}</div>,
    },
    {
      width: "70%",
      label: "Week",
      sortName: "start",
      render: (row) => (
        <div>
          {row.start && moment(row.start).tz(row.client.timezone).format("ll")}{" "}
          - {row.end && moment(row.end).tz(row.client.timezone).format("ll")}
        </div>
      ),
    },
    {
      width: "10%",
      label: (
        <Popup
          position="top center"
          content={
            <div>
              <Icon name="money" color="green" /> = Paid <br />
              <Icon name="file" color="green" /> = Billed <br />
              <Icon name="delete" color="blue" /> = Errored <br />
              <Icon name="minus" color="grey" /> = Generated <br />
              <Icon color="white" name="newspaper" /> = In-Review
            </div>
          }
          trigger={<div>Status</div>}
        />
      ),
      render: (row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {row.status === "paid" ? (
            <Icon color="green" name="money" />
          ) : row.status === "errored" ? (
            <Icon color="blue" name="delete" />
          ) : row.status === "billed" ? (
            <Icon color="green" name="file" />
          ) : row.status === "generated" ? (
            <Icon color="grey" name="minus" />
          ) : (
            <Icon color="white" name="newspaper" />
          )}
        </div>
      ),
    },
    {
      width: "80%",
      label: "",
      render: (row) => (
        <div
          style={{
            flexDirection: "row",
            display: "flex",
          }}
        >
          {row.status === "errored" || row.status === "generated" ? (
            <Button
              compact
              size="large"
              color="blue"
              type="button"
              style={{ margin: "auto" }}
              onClick={() => sendReview(row._id)}
              content="Send For Review"
            />
          ) : row.status === "billed" && user.accountType !== "client" ? (
            <Button
              compact
              size="large"
              color="blue"
              type="button"
              style={{ margin: "auto" }}
              onClick={() => setPaid(row._id)}
              content="Set Paid"
            />
          ) : (
            row.status === "paid" &&
            user.accountType !== "client" && (
              <Button
                compact
                size="large"
                color="blue"
                type="button"
                style={{ margin: "auto" }}
                onClick={() => setAddCheck(row)}
                content={
                  row.check.number || row.check.url ? "Edit Check" : "Add Check"
                }
              />
            )
          )}
          <Popup
            position="top right"
            content="View Report"
            trigger={
              <Button
                icon
                type="button"
                style={{ marginRight: "5px", marginLeft: "auto" }}
                onClick={() => onView(row)}
                content={<Icon name="eye" color="blue" size="large" />}
              />
            }
          />
          <Popup
            position="top right"
            content="Delete a report"
            trigger={
              <Button
                color="red"
                icon
                type="button"
                onClick={() => deleteReport(row._id)}
                content={<Icon name="delete" size="large" color="white" />}
              />
            }
          />
        </div>
      ),
    },
  ];

  const fetchReports = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/reports`, {
        params: {
          page: pagination.page,
          limit: pagination.limit,
          client: filters.client,
          status: filters.status !== "all" ? filters.status : null,
          start: daterange.start,
          end: daterange.end,
          sort: sort.sort,
          direction: sort.direction,
        },
      })
      .then(async (res) => {
        setReports(res.data.reports);
        setPagination((old) => ({ ...old, total: res.data.total }));
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setError(message);
      })
      .finally(() => setLoading(false));
  };
  function sendReview(id) {
    axios
      .put(`${process.env.REACT_APP_API_URL}/report/send`, {
        id: id,
      })
      .then(async (res) => {
        setMessage("Report has been sent to client for approval");
        fetchReports();
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setError(message);
      })
      .finally(() => setLoading(false));
  }
  function deleteReport(id) {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/report/remove`, {
        params: {
          id: id,
        },
      })
      .then(async (res) => {
        setMessage("Report has been deleted");
        fetchReports();
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setError(message);
      })
      .finally(() => setLoading(false));
  }
  function fetchClients() {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/client/suggestions`, {
        params: {
          id: cookie.get("id"),
        },
      })
      .then(async (res) => {
        var clients = res.data;
        var temp = [];
        clients.forEach((client) => {
          temp.push({ label: client.name, value: client._id });
        });
        setClientSuggestions(temp);
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setError(message);
      })
      .finally(() => setLoading(false));
  }
  const setPaid = (id) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/report/paid`, {
        id: id,
      })
      .then(async (res) => {
        setMessage("Report has been set to paid");
        fetchReports();
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setError(message);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    fetchReports();
  }, [filters, sort, pagination.page, pagination.limit, daterange]);

  return (
    <Main>
      <Button
        style={{
          marginTop: "auto",
          position: "absolute",
          top: "70px",
          right: "20px",
        }}
        icon
        color="blue"
        onClick={() => setSelected({ create: true })}
      >
        <Icon name="add" color="white" />
        Create Report
      </Button>
      <FilterRow>
        <SelectorSearch
          width="500px"
          label="Client"
          onSelect={(v) => setFilters((old) => ({ ...old, client: v }))}
          value={filters.client}
          options={clientSuggestions}
        />
        <Selector
          width="300px"
          label="Status"
          onSelect={(v) => setFilters((old) => ({ ...old, status: v }))}
          value={filters.status}
          options={[
            { label: "All", value: "all" },
            { label: "Generated", value: "generated" },
            { label: "In-Review", value: "in-review" },
            { label: "Errored", value: "errored" },
            { label: "Billed", value: "billed" },
            { label: "Paid", value: "paid" },
          ]}
        />
        <DatePicker
          type="date"
          label="Daterange search will list reports"
          width="20%"
          max={moment(daterange.end).format("YYYY-MM-DD")}
          onChange={(v) => {
            setDaterange((old) => ({ ...old, start: v }));
          }}
          onFirst={(v) =>
            !daterange.end &&
            setDaterange((old) => ({
              ...old,
              end: moment(v).add(6, "days").format("YYYY-MM-DD"),
            }))
          }
          value={moment(daterange.start).format("YYYY-MM-DD")}
        />{" "}
        <DatePicker
          type="date"
          label="based on Report Start Date"
          width="20%"
          min={moment(daterange.start).format("YYYY-MM-DD")}
          onChange={(v) => setDaterange((old) => ({ ...old, end: v }))}
          value={moment(daterange.end).format("YYYY-MM-DD")}
        />
        <Button
          style={{ marginTop: "auto" }}
          icon
          color="blue"
          compact
          circular
          onClick={fetchReports}
        >
          <Icon name="redo" color="white" />
        </Button>
      </FilterRow>
      <Table
        loading={loading}
        data={reports}
        pagination={pagination}
        headers={headers}
        sortBy={sort.sort}
        sortDirection={sort.direction}
        onPage={(page) => setPagination((old) => ({ ...old, page }))}
        onLimit={(limit) => setPagination((old) => ({ ...old, limit }))}
        onSort={(sort, direction) => setSort({ sort, direction })}
      />
      <Modal
        open={!!message}
        closeIcon
        onClose={() => {
          setMessage("");
        }}
        style={{
          maxWidth: 500,
          textAlign: "center",
          padding: "1%",
        }}
      >
        <Header
          as="h2"
          style={{ backgroundColor: Theme.PAGE_BACKGROUND, color: "white" }}
        >
          {message}
        </Header>
      </Modal>
      <Report
        selected={selected}
        onClose={(report) => {
          setSelected(null);
          report && onView(report);
        }}
      />
      {addCheck && (
        <CheckModal
          report={addCheck}
          setCheck={setAddCheck}
          onClose={() => setAddCheck(null)}
        />
      )}
    </Main>
  );
};
const mapStateToProps = (state) => ({ ...state.current });
export default connect(mapStateToProps, null)(ReportList);
