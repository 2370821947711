import React, { useState } from "react";
import Logo from "../../assets/logos/banner.png";
import Nitely from "../../assets/logos/nitely.png";
import axios from "axios";
import cookie from "js-cookie";
import { useWindowDimensions } from "hooks";
import { clearCookies } from "globalResources/helpers";
import Input from "globalComponents/Input";
import styled from "styled-components";
import { Button } from "semantic-ui-react";
import { getTheme } from "../../Theme.js";
import { errorHandler } from "globalResources/util";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const theme = getTheme();

const Main = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  min-height: 100vh;
  width: 100%;
`;
const Banner = styled.img`
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.MAIN_COLOR};
  height: 100vh;
  min-width: 50vh;
  gap: 10px;
  width: 100%;
  align-items: center;
  padding: 50px 18px 10px 18px;
`;

const Bulletin = styled.div`
  background: ${theme.MANILLA};
  color: ${theme.MESSAGE_TEXT};
  border-radius: 10px;
  padding: 16px;
`;

const Title = styled.h2``;

const Paragraph = styled.p`
  font-size: 16px;
  font-weigth: 500;
`;

const ListItem = styled.li`
  font-size: 16px;
  font-weigth: 500;
`;

const Error = styled.h2`
  color: ${theme.NEGATIVE};
`;

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const submit = () => {
    if (!email) setError("Email is required");
    else {
      setLoading(true);
      setError("");
      axios
        .post(`${process.env.REACT_APP_API_URL}/login`, {
          email: email.includes("@") ? email : null,
          phone: email,
          password: password,
        })
        .then(async (res) => {
          if (res.data.token) {
            await clearCookies();
            //set token
            cookie.set("token", res.data.token, { expires: 0.9 }); //set cookie to expire in one day
            cookie.set("type", "user", { expires: 0.9 });
            cookie.set(
              "name",
              res.data.user.firstName + " " + res.data.user.lastName
            );
            cookie.set("id", res.data.user._id);
            axios.defaults.headers.common["Authorization"] = res.data.token;
            window.location.reload();
          } else {
            window.alert(
              `Check your ${
                email.includes("@") ? "email" : "phone"
              } for the sign in link`
            );
          }
        })
        .catch((err) => {
          const { message } = errorHandler(err);
          setError("Login Failed Try Again");
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Main>
      <LoginContainer>
        <Banner src={Logo} />
        <Input
          label="Email or Phone"
          name="username"
          onChange={(e) => setEmail(e.target.value)}
        />
        {password !== undefined && (
          <Input
            label="Password"
            name="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && submit()}
          />
        )}
        <Button
          loading={loading}
          color="blue"
          content={password === undefined ? "Get Sign in Link" : "Login"}
          onClick={submit}
        />
        {password === undefined && (
          <Button
            color="blue"
            content="Use Password"
            onClick={() => setPassword("")}
          />
        )}
        <Button onClick={() => history.push("/password")}>
          Reset Your Password
        </Button>
        <Error>{error}</Error>
        {cookie.get("timestationToken") && (
          <Button
            color="green"
            style={{ marginTop: "auto" }}
            onClick={() => history.push("/timestation")}
          >
            Go To Timestation
          </Button>
        )}
        <Paragraph style={{ marginTop: "auto", textAlign: "center" }}>
          © {moment().format("yyyy")} Nitely Development LLC{" "}
        </Paragraph>
      </LoginContainer>
      <InfoContainer>
        <Bulletin>
          <Title style={{ textAlign: "center", fontSize: "28px" }}>
            Update Bulletin
          </Title>
          <Title>Timestations now saved to your browser!</Title>
          <Paragraph>
            Setup your timestation by logging in on any device and it will be
            accessible without logging in again as long as the browser cache
            isn't cleared. After its setup you'll see a "Go To Timestation" on
            this page below the login form.
          </Paragraph>

          <Paragraph>
            Employees now can only be added via the new Client Details page
            (accessed via the clients list). Multiple Positions at the same
            client are now also possible each with different bill rates.
          </Paragraph>

          <Paragraph>
            If you have any issues please contact us at{" "}
            <a href="mailto:support@nitely.dev">support@nitely.dev</a>
          </Paragraph>
          <Title>Latest Updates</Title>
          <ul>
            <ListItem>Check file/image uploading</ListItem>
            <ListItem>Permanent Browser Timestation</ListItem>
            <ListItem>Client Details Page</ListItem>
            <ListItem>Invoice refactoring</ListItem>
            <ListItem>Multi-Position Employees</ListItem>
          </ul>
          <Title>Coming Soon</Title>
          <ul>
            <ListItem>User Details Page</ListItem>
            <ListItem>Single Sign On via google</ListItem>
            <ListItem>Application performance updates</ListItem>
            <ListItem>Employee mass messaging</ListItem>
            <ListItem>Analytics Update</ListItem>
          </ul>
          <Title>Have suggestions for us?</Title>
          <Paragraph>
            Contact us with your feature requests at{" "}
            <a href="mailto:support@nitely.dev">support@nitely.dev</a>, or use
            the Bug Report feature after loggin into TCS.
          </Paragraph>
        </Bulletin>
        <Title style={{ color: "white", marginTop: "auto" }}>
          TCS Works on your phone! Text: "hi" to 615-235-5338
        </Title>
        <a
          style={{
            margin: "auto auto 10px auto",
          }}
          target="_blank"
          rel="noreferrer"
          href="https://nitely.dev"
        >
          <img
            style={{
              height: "100px",
              borderRadius: "100px",
            }}
            src={Nitely}
            alt=""
          />
        </a>
      </InfoContainer>
    </Main>
  );
};
export default Login;
