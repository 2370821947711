import React from "react";
import {
	Button,
	Icon,
	Header,
	Form,
	Grid,
	Popup,
	TextArea,
} from "semantic-ui-react";
import {
	DatePicker,
	FolderContainer,
	Input,
	SelectorSearch,
} from "../../globalComponents";
import axios from "axios";
import cookie from "js-cookie";
import moment from "moment-timezone";
import { errorHandler } from "globalResources/util";
import { getClientEmployeeOptions } from "api/client";

class BatchEntries extends React.Component {
	state = {
		times: [],
		loading: false,
		error: { general: "", times: [], nulls: 0 },
		errors: [],
		selected: null,
		search: "",
		start: null,
		end: null,
		users: [],
		searchBy: "user",
		permission: null,
		clients: [],
		client: null,
		clientSearch: "",
		page: 1,
		limit: 20,
		sort: "start",
		direction: -1,
	};

	fetchClients() {
		axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
			"token"
		)}`;
		axios
			.get(`${process.env.REACT_APP_API_URL}/client/suggestions`, {
				params: {
					search: this.state.clientSearch,
				},
			})
			.then(async res => {
				if (res.data.length < 1) {
					this.setState(
						{
							clients: [{ text: "No Clients", value: "none" }],
							loading: false,
						},
						this.fetchUsers
					);
				} else {
					var clients = res.data;
					var temp = [];
					clients.forEach(client => {
						if (client.name !== "All")
							temp.push({ text: client.name, value: client._id });
					});
					this.setState(
						{
							clients: temp,
							client: temp[0].value,
							loading: false,
						},
						this.fetchUsers
					);
				}
			})
			.catch(err => this.setState({ err: err }));
	}
	fetchUsers = async () => {
		this.setState({ users: await getClientEmployeeOptions(this.state.client) });
	};

	submit = () => {
		const { client, user, times, error } = this.state;
		let tempErr = error;
		if (times.length < 1) {
			tempErr.general = "Must create at least 1 time entry";
			this.setState({ error: tempErr });
		} else if (!client || !user) {
			tempErr.general = "Must Select a user and client";
			this.setState({ error: tempErr });
		} else if (error.nulls < 1) {
			tempErr.general = "";
			this.setState({ loading: true, error: tempErr });
			axios
				.post(`${process.env.REACT_APP_API_URL}/timesheet/batch`, {
					user: user.user._id,
					position: user.position,
					client: client,
					times: times,
				})
				.then(res => {
					tempErr.general = "";
					this.setState({
						loading: false,
						message: "Times added successfully ",
					});
				})
				.catch(error => {
					let { message, action } = errorHandler(error);
					this.setState({
						error: { ...this.state.error, general: message },
						loading: false,
					});
				});
		} else {
			tempErr.general = "Time Entry Missing required field";
			this.setState({ error: tempErr });
		}
	};
	setTime = (name, value, index) => {
		let temp = this.state.times;
		let tempErr = this.state.error;
		tempErr.times[index][name] = "";
		if (!tempErr.times[index].start && !tempErr.times[index].end)
			tempErr.nulls -= 1;
		if (name === "notes" || name === "position") temp[index][name] = value;
		else temp[index][name] = moment(value).utc();
		this.setState({ times: temp, error: tempErr });
	};
	componentDidMount() {
		this.fetchClients();
	}
	render() {
		const {
			error,
			errors,
			loading,
			user,
			users,
			clients,
			client,
			times,
			message,
		} = this.state;
		return (
			<FolderContainer title="Batch Time Entry">
				<Button color="blue" onClick={this.props.onClose}>
					<Icon name="angle left" />
					Back
				</Button>
				<Header color="red">{error.general}</Header>
				<Header>{message}</Header>

				{errors.map(err => (
					<div style={{ display: "flex", flexDirection: "row" }}>
						<Header margin="auto">{`Error with time entry number ${err.index}:`}</Header>
						<Header margin="0" color="red">
							{err.error}
						</Header>
					</div>
				))}
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						margin: " 0 auto 3% auto",
						flexWrap: "wrap",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							marginRight: "5%",
						}}
					>
						<Header as="h4">Client</Header>
						<Form.Dropdown
							onChange={(event, { name, value }) => {
								this.setState({ client: value }, this.fetchUsers);
							}}
							search
							onSearchChange={v =>
								this.setState({ clientSearch: v.target.value }, this.fetchUsers)
							}
							selection
							value={client}
							options={clients}
						/>
					</div>

					<div
						style={{
							display: "flex",
							flexDirection: "column",
							marginRight: "5%",
						}}
					>
						{" "}
						<SelectorSearch
							label="Employee"
							error={errors.user}
							placeholder="Select a client first"
							onSelect={v => {
								this.setState({ user: v });
							}}
							onSearch={v => {
								this.setState({ search: v.target.value }, this.fetchUsers);
							}}
							value={user}
							options={users}
						/>
					</div>
					<Button
						color="blue"
						style={{ margin: "1vh 0 auto auto" }}
						onClick={this.submit}
						loading={loading}
					>
						Submit Batch Time Entries
					</Button>
				</div>
				<Grid divided="vertically">
					{times.map((time, index) => (
						<Grid.Row>
							<Button
								style={{ margin: "1% 0% auto .5%" }}
								icon
								compact
								color="red"
								onClick={() => {
									let temp = times;
									times.splice(index, 1);
									this.setState({ times: temp });
								}}
							>
								<Icon name="close" />
							</Button>
							<Grid.Column width={3}>
								<Header as="h3" style={{ margin: "auto" }}>
									Time In
								</Header>
								<DatePicker
									max={moment.utc(time.end).local().format("YYYY-MM-DDTHH:mm")}
									onChange={v => this.setTime("start", v, index)}
									onFirst={v => !time.end && this.setTime("end", v, index)}
									value={moment
										.utc(time.start)
										.local()
										.format("YYYY-MM-DDTHH:mm")}
								/>
								<Header color="red">{error.times[index].start}</Header>
							</Grid.Column>
							<Grid.Column width={3}>
								<Header as="h3" style={{ margin: "auto" }}>
									Time Out
								</Header>
								<DatePicker
									min={moment
										.utc(time.start)
										.local()
										.format("YYYY-MM-DDTHH:mm")}
									onChange={v => this.setTime("end", v, index)}
									value={moment
										.utc(time.end)
										.local()
										.format("YYYY-MM-DDTHH:mm")}
								/>
								<Header color="red">{error.times[index].end}</Header>
							</Grid.Column>

							<Grid.Column stretched width={4}>
								<Header as="h3" style={{ margin: "auto" }}>
									Notes
								</Header>
								<TextArea
									value={time.notes}
									onChange={(e, d) => this.setTime("notes", d.value, index)}
								/>
							</Grid.Column>
						</Grid.Row>
					))}
				</Grid>
				<Button
					color="blue"
					style={{ margin: "1vh auto auto auto" }}
					onClick={() => {
						let temp = times;
						let tempErr = error;
						temp.push({
							start: null,
							end: null,
							notes: "",
						});
						tempErr.times.push({ start: "Required", end: "Required" });
						tempErr.nulls += 1;
						this.setState({ times: temp, error: tempErr });
					}}
				>
					<Icon name="add" />
					Add Time
				</Button>
				<Button
					loading={loading}
					color="blue"
					floated="right"
					onClick={this.submit}
				>
					Submit Batch Time Entries
				</Button>
			</FolderContainer>
		);
	}
}
export default BatchEntries;
