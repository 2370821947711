import axios from "axios";
import { errorHandler } from "globalResources/util";
import jsCookie from "js-cookie";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setUser } from "store/slices/userSlice";
import styled from "styled-components";

const Main = styled.div`
	display: flex;
	flex-direction: column;
`;

const HandleLoginLink = () => {
	const { token } = useParams();

	useEffect(() => {
		async function setToken() {
			await jsCookie.set("token", token);
			window.location.reload();
		}
		setToken();
	}, [token]);

	return <Main>Loggin you in...</Main>;
};
export default HandleLoginLink;
