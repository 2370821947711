import React from "react";
import axios from "axios";
import cookie from "js-cookie";
import { Message, Header, Image } from "semantic-ui-react";
import Theme from "../Theme.js";
import Logo from "../assets/logos/full.png";
import { clearCookies } from "globalResources/helpers/index.js";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    clearCookies();
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/bugReport`, {
        issue: error.toString() + "\n" + JSON.stringify(errorInfo),
      })
      .then(async (res) => {
        if (res.data.error === "Not Authorized") {
          await cookie.remove("token");

          window.location.reload();
        }
      })
      .catch((err) =>
        this.setState({ loading: false, error: "Failed to send bug report" })
      );
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            backgroundColor: Theme.PAGE_BACKGROUND,
            display: "flex",
            height: "100vh",
          }}
        >
          <Message
            style={{
              justifyContent: "center",
              textAlign: "center",
              gap: "20px",
              display: "flex",
              flexDirection: "column",
              maxWidth: "500px",
              margin: "auto",
            }}
          >
            <Image
              style={{
                margin: "0vh auto 1vh auto",
                height: 200,
              }}
              src={Logo}
            />
            <Header as="h1">An error has occured!</Header>
            <Header as="h3" color="grey">
              A bug report has been sent and your issue will be resolved soon.
              For, an update or to provide more information please email
              support@nitely.dev.
            </Header>
            <a
              style={{
                backgroundColor: "grey",
                color: "white",
                borderRadius: 7,
                height: "40px",
                width: "80%",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                margin: "auto",
              }}
              href={"https://staffnmore.timecardsolutions.com"}
            >
              Back To TCS Login
            </a>
          </Message>
        </div>
      );
    }

    return this.props.children;
  }
}
