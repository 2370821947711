import styled from "styled-components";
import { getTheme } from "Theme.js";

const theme = getTheme();

export { default as NavBar } from "./NavBar.js";
export { default as ClientNavBar } from "./ClientNavBar.js";
export { default as ProfileButton } from "./ProfileButton.js";
export { default as StandardTable } from "./StandardTable.js";
export { default as PermissionDenied } from "./PermissionDenied.js";
export { default as Settings } from "./Settings.js";
export { default as Confirm } from "./Confirm.js";
export { default as DatePicker } from "./datepicker.js";
export { default as ClientTime } from "./ClientTime.js";
export { default as Time } from "./TimeModal.js";
export { default as Adjustment } from "./Adjustment.js";
export { default as NavigationBar } from "./NavigationBar.js";
export { default as SearchBar } from "./SearchBar";
export { default as FolderContainer } from "./FolderContainer";
export { default as SelectorSearch } from "./SelectorSearch";
export { default as Selector } from "./Selector";
export { default as Button } from "./Button";
export { default as Table } from "./Table";
export { default as InfiniteTable } from "./InfiniteTable";
export { default as ToggleButton } from "./ToggleButton";
export { default as Input } from "./Input.jsx";
export { default as Modal } from "./Modal";

export const Row = styled.div`
  display: flex;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 4px 16px;
`;

export const FormHeader = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  padding: 4px;
  margin-bottom: 5px;
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 5px;
`;

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${theme.INPUT_BORDER};
  height: 250px;
  gap: 2px;
  overflow-y: auto;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${theme.INPUT_BACKGROUND};
  color: ${theme.INPUT_TEXT};
  border-radius: 4px;
  padding: 4px;
  border: 2px solid ${theme.INPUT_BACKGROUND};
  font-weight: 600;
  gap: 5px;
  ${(props) =>
    props.onClick &&
    `cursor: pointer; 
  :hover {
    border: 2px solid ${theme.MAIN_COLOR};
  }
  `}
`;

export const CurvedHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  font-weight: 800;
  color: white;
  padding: 8px;
  border-bottom: 2px solid ${theme.BLACK};
  background: ${theme.MAIN_COLOR};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  min-height: 40px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`;

export const Error = styled.h3`
  color: ${theme.NEGATIVE};
  margin: 0;
`;
