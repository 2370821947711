import { CurvedHeader, Input, Selector } from "globalComponents";
import React, { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
import cookie from "js-cookie";
import axios from "axios";
import moment from "moment-timezone";
import { errorHandler } from "globalResources/util";
import jsCookie from "js-cookie";
import Logo from "../../assets/logos/banner.png";
import { getTheme } from "Theme";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100vh;
  background-color: ${theme.MANILLA};
  text-align: center;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Container = styled.div`
  background-color: ${theme.PAGE_BACKGROUND};
  padding: 20px;
  width: 80%;
  justify-content: center;
  display: flex;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;
const Clock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: white;
  padding: 5px;
`;
const TimeLabel = styled.span`
  font-size: 32px;
  font-weight: 700;
`;

const Header = styled.h1``;
const Banner = styled.img`
  width: 70%;
`;

const Time = styled.span`
  font-size: 24px;
  margin-left: 10px;
`;

const defaultState = {
  user: null,
  note: "",
  password: "",
  verified: null,
};

const ExternalTimeStation = () => {
  const client = jsCookie.get("timestationClient");
  const [spanish, setSpanish] = useState(false);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [error, setError] = useState("");
  const [state, setState] = useState(defaultState);
  const [lastCheck, setLastCheck] = useState(moment());

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
      "timestationToken"
    )}`;
    getInfo();
    fetchUsers();
  }, [search]);

  useEffect(() => {
    setTimeout(() => window.location.reload(), 1 * 60 * 60 * 1000);
  }, []);

  const getInfo = async () => {
    let { data } = await axios.get("/timestation/info");
    setInfo(data);
  };

  const fetchUsers = () => {
    setLoading(true);
    axios
      .get(`/timestation/users`, {
        params: {
          search: search,
        },
      })
      .then(async (res) => {
        const employees = res.data;
        var temp = [];
        employees.forEach((e) => {
          temp.push({
            label: `${e.user.firstName} ${e.user.lastName} (${e.position}) `,
            value: e.user._id + "|" + e.position,
          });
        });
        temp.sort((a, b) => (a.label > b.label ? 1 : -1));
        setUsers(temp);
        if (temp[0])
          setState((old) => ({
            ...old,
            user: temp[0].value.split("|")[0],
            position: temp[0].value.split("|")[1],
          }));
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  const verify = () => {
    const { user, password, verified } = state;
    if (!user) setError("Must select user");
    else if (!password) setError("Must provide your password");
    else {
      setLoading(true);
      axios
        .post(`/timestation/login`, {
          userId: state.user,
          password: state.password,
        })
        .then((res) => {
          setState((old) => ({ ...old, verified: res.data.user }));
          setTimeout(logout, 1000 * 60);
          setError(null);
        })
        .catch(async (err) => {
          setError(
            "login failed check that your pin is correct or contact a manger to change it for you."
          );
        })
        .finally(() => setLoading(false));
    }
  };
  const startTimer = async () => {
    const { verified } = state;
    axios
      .post(`/timestation/start`, {
        start: moment().utc(),
        userId: verified._id,
        position: state.position,
      })
      .then(async (res) => {
        setState((old) => ({ ...old, verified: res.data.user, clockin: true }));
        setError(null);
        setTimeout(logout, 1000);
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setError(message);
      });
  };

  const endTimer = () => {
    const { verified } = state;
    axios
      .post(`/timestation/end`, {
        end: moment().utc(),
        note: state.note,
        userId: verified._id,
        position: state.position,
      })
      .then(async (res) => {
        logout();
        setError(null);
      })
      .catch((err) => {
        setError("Unable to create time entry");
      });
  };
  const cancelTimer = () => {
    const { verified } = state;
    axios
      .post(`/timestation/cancel`, {
        userId: verified._id,
      })
      .then(async (res) => {
        setState((old) => ({
          ...old,
          permission: res.data.permission,
          verified: res.data.user,
        }));
        setError(null);
      })
      .catch((err) => {
        setError("Error canceling timer");
      });
  };

  const logout = () => {
    if (users[0]) {
      setState(() => ({
        ...defaultState,
        user: users[0].value.split("|")[0],
        position: users[0].value.split("|")[1],
      }));
    } else setState(defaultState);
  };
  if (!info) return null;

  const checkEmployeePull = () => {
    console.log(lastCheck.diff(moment()));
    if (lastCheck.diff(moment()) < -10000) {
      setLastCheck(moment());
      fetchUsers();
    }
  };

  return (
    <Main onClick={checkEmployeePull}>
      <Banner src={Logo} />
      <CurvedHeader style={{ minWidth: "80%", marginTop: "20px" }}>
        <Header style={{ fontSize: "32px" }}>{info.name} Time Station</Header>
      </CurvedHeader>
      <Container>
        {false && (
          <Button onClick={() => setSpanish(true)}>
            {spanish ? "English" : "Espanol"}
          </Button>
        )}
        {!state.verified ? (
          <Column>
            <Selector
              label="Employee (position)"
              options={users}
              onSelect={(v) => {
                setState((old) => ({
                  ...old,
                  user: v.split("|")[0],
                  position: v.split("|")[1],
                }));
              }}
            />
            <Input
              type="password"
              value={state.password}
              label="Pin"
              onChange={(v) =>
                setState((old) => ({ ...old, password: v.target.value }))
              }
            />
            <Button style={{ width: "60vw" }} color="blue" onClick={verify}>
              Login
            </Button>
          </Column>
        ) : (
          <Column>
            <TimeLabel style={{ fontSize: "28px", marginBottom: 30 }}>
              {moment().format("a") === "pm"
                ? "Good Afternoon"
                : "Good Morning"}{" "}
              {state.verified.firstName + " " + state.verified.lastName}
            </TimeLabel>
            {state.verified.currentTimer && (
              <Clock>
                <Row>
                  <TimeLabel>Clock In: </TimeLabel>
                  <Time>
                    {moment(state.verified.currentTimer.start).format("lll")}
                  </Time>
                </Row>
                <Row>
                  <TimeLabel>Duration: </TimeLabel>
                  <Time>
                    {moment
                      .duration(
                        moment().diff(moment(state.verified.currentTimer.start))
                      )
                      .asHours()
                      .toFixed(2)}
                    hrs
                  </Time>
                </Row>
              </Clock>
            )}
            {state.verified.currentTimer ? (
              <Row>
                <Button style={{ width: "20vw" }} color="blue" onClick={logout}>
                  Logout
                </Button>
                <Button style={{ width: "20vw" }} onClick={cancelTimer}>
                  Cancel
                </Button>
                <Button
                  style={{ width: "20vw" }}
                  color="red"
                  onClick={endTimer}
                >
                  Clock-Out
                </Button>
              </Row>
            ) : (
              <Row>
                <Button
                  style={{ width: "20vw" }}
                  onClick={() => {
                    setState((old) => ({
                      ...old,
                      user: null,
                      verified: null,
                      password: null,
                    }));
                    setError(null);
                  }}
                >
                  Log Out
                </Button>
                <Button
                  style={{ width: "20vw" }}
                  color="green"
                  onClick={startTimer}
                >
                  Clock-In
                </Button>
              </Row>
            )}
          </Column>
        )}
      </Container>
      <Header style={{ color: theme.NEGATIVE }}>{error}</Header>
    </Main>
  );
};
export default ExternalTimeStation;
