import React, { useState } from "react";
import {
  Button,
  Icon,
  Header,
  Grid,
  Tab,
  Modal,
  Popup,
} from "semantic-ui-react";
import { Table, Time, Adjustment, Row } from "../../../globalComponents";
import axios from "axios";
import cookie from "js-cookie";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import Theme from "Theme";
import NotesModal from "globalComponents/NotesModal";
import { errorHandler } from "globalResources/util";
import TotalsSection from "./TotalsSection";
import styled from "styled-components";
import { getTheme } from "Theme";
const theme = getTheme();

const adjustmentHeaders = [
  {
    label: "User",
    render: (row) => <div>{row.user.firstName + " " + row.user.lastName}</div>,
  },
  {
    label: "Created By",
    render: (row) => <div>{row.createdBy || "Client"}</div>,
  },
  {
    label: "Amount",
    render: (row) => <div>$ {parseFloat(row.amount / 100).toFixed(2)} </div>,
  },
  {
    label: (
      <Popup
        position="top center"
        content={
          <div>
            <Icon name="plus" color="green" /> = bonus <br />
            <Icon name="calculator" color="blue" /> = adjustment <br />
          </div>
        }
        trigger={<div>Type</div>}
      />
    ),
    width: "15%",
    centered: true,
    render: (row) =>
      row.bonus ? (
        <Icon
          style={{ margin: "0 auto 0 auto" }}
          name="plus"
          color="green"
          size="small"
          circular
        />
      ) : (
        <Icon
          style={{ margin: "0 auto 0 auto" }}
          name="calculator"
          color="blue"
          size="small"
          circular
        />
      ),
  },
];

const UserOption = styled.div`
  background-color: ${(props) =>
    props.selected ? theme.BUTTON_COLOR_GREY : theme.MAIN_COLOR};
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
  border: 2px solid white;
  :hover {
    opacity: 0.85;
  }
`;

const ClientInfo = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.PAGE_BACKGROUND};
  padding: 20px;
  height: 100%;
  border-radius: 20px;
`;
const ViewReport = (props) => {
  const [pulling, setPulling] = useState(false);
  const [state, setState] = useState({
    loading: false,
    timesheets: [],
    search: null,
    searchBy: "name",
    currUser: 0,
    selected: null,
    message: null,
    adjust: null,
    limit: 20,
    page: 1,
    sortBy: "end",
    direction: 1,
  });
  const currentUser = useSelector((state) => state.current.user);
  const treatClient = currentUser.accountType === "client";
  function pullEntries(id) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
      "token"
    )}`;
    setPulling(true);
    axios
      .put(`${process.env.REACT_APP_API_URL}/report/pullEntries`, {
        id: id,
      })
      .then(async (res) => {
        setState({ ...state, message: "Succesfully pulled in time entries" });
        props.refresh();
      })
      .catch((err) => {
        let { message, action } = errorHandler(err);
        setState({ ...state, loading: false, message });
      })
      .finally(() => setPulling(false));
  }

  function paginate(sheets) {
    const { page, limit, sortBy, direction } = state;
    var temp = [...sheets];
    temp.sort((a, b) =>
      direction == 1
        ? a[sortBy] > b[sortBy]
          ? 1
          : -1
        : a[sortBy] > b[sortBy]
        ? -1
        : 1
    );
    return temp.slice(limit * (page - 1), limit * (page - 1) + limit);
  }
  function getHeaders(user) {
    const { report } = props;
    const getHours = (row) => {
      return (
        moment.duration(moment(row.end).diff(moment(row.start))).asHours() -
        (report.client.subtractLunch && !row.user.overrideLunch ? 0.5 : 0)
      ).toFixed(2);
    };
    var names = [
      {
        label: "User",
        render: (row) => (
          <div>{row.user.firstName + " " + row.user.lastName}</div>
        ),
      },
      {
        label: "Updated By",
        render: (row) => (
          <div>
            {row.notes.length > 1 && row.notes[row.notes.length - 1].user}
          </div>
        ),
      },
      {
        label: "Position",
        width: "40%",
        render: (row) => <div>{row.position}</div>,
      },
    ];
    names = names.concat([
      {
        label: "Start Time",
        sortBy: "start",
        render: (row) => (
          <div>
            {row.start &&
              moment(row.start)
                .tz(report.client.timezone)
                .format("ddd. MM/DD/yy hh:mm A")}
          </div>
        ),
      },
      {
        label: "End Time",
        sortBy: "end",
        render: (row) => (
          <div>
            {row.end &&
              moment(row.end)
                .tz(report.client.timezone)
                .format("ddd. MM/DD/yy hh:mm A")}
          </div>
        ),
      },
      {
        label: "Hours",
        width: "40%",
        centered: true,
        render: (row) => <div> {getHours(row)} hrs</div>,
      },
      {
        label: (
          <Popup
            position="left center"
            content={
              <div>
                <Header>How the time was added</Header>
                <br />
                <Icon name="mail" color="purple" /> = SMS <br />
                <Icon name="male" color="blue" /> = Manager <br />
                <Icon name="desktop" color="yellow" /> = Time Station <br />
                <Icon name="server" color="yellow" /> = Auto Clock-Out <br />
                <Icon name="mobile" color="green" /> = Mobile App <br />
                <Icon name="location arrow" color="green" /> = Location verified{" "}
                <br />
                <Icon name="cancel" color="red" /> = Location not verified
              </div>
            }
            trigger={<div>Type</div>}
          />
        ),
        width: "15%",
        centered: true,
        render: (row) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {row.type === "sms" || row.sms ? (
              <Icon color="purple" name="mail" />
            ) : row.type === "app" ? (
              <>
                <Icon color="green" name="mobile" />
                <Icon
                  color={row.gps ? "green" : "red"}
                  name={row.gps ? "location arrow" : "cancel"}
                />
              </>
            ) : row.type === "station" ? (
              <Icon color="yellow" name="desktop" />
            ) : row.type === "auto" ? (
              <Icon color="red" name="server" />
            ) : (
              <Icon color="blue" name="male" />
            )}
          </div>
        ),
      },
      {
        label: "Bill Rate",
        width: "30%",
        render: (row) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            ${(row.billRate / 100).toFixed(2)} / hr
          </div>
        ),
      },
    ]);
    if (!treatClient)
      names = names.concat([
        {
          label: "Pay Rate",
          width: "30%",
          render: (row) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              ${(row.payRate / 100).toFixed(2)} / hr
            </div>
          ),
        },
      ]);
    return names;
  }

  function getAmount(index) {
    const { totals, report, groups } = props;
    if (index === 0) {
      return totals;
    } else if (index === 1) {
      var all = {
        hours: 0,
        overtimeHours: 0,
        paid: 0,
        billed: 0,
        overtimePaid: 0,
        overtimeBilled: 0,
        profit: 0,
      };
      report.adjustments.map(
        (adj) => (all.billed += parseFloat(adj.amount) / 100)
      );
      return all;
    } else {
      return groups[index - 2].totals;
    }
  }
  function getPanes(groups) {
    var panes = [{ menuItem: "All" }, { menuItem: "Adjustments" }];
    groups.forEach((group) =>
      panes.push({
        menuItem: `${group.position}: ${group.user.firstName} ${group.user.lastName}`,
        id: group._id,
      })
    );
    return panes;
  }
  function viewAll(groups) {
    var all = [];
    groups.forEach((group) => {
      group.times.forEach((time) => {
        time.user = group.user;
        all.push(time);
      });
    });
    return all;
  }

  const onSort = (by, direction) => {
    const { report } = props;
    report.timesheets.sort((a, b) =>
      a[by] > b[by] ? (direction ? 1 : 0) : direction ? 0 : 1
    );
    setState((old) => ({ ...old, sortBy: by, direction }));
  };

  const {
    loading,
    currUser,
    selected,
    message,
    adjust,
    page,
    limit,
    sortBy,
    direction,
  } = state;
  const { report, onBack } = props;
  const sheets = report.timesheets;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        verticalAlign: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button
          style={{
            position: "absolute",
            top: "65px",
            right: "10px",
            height: 40,
          }}
          onClick={onBack}
        >
          <Icon name="left arrow" />
          Back
        </Button>
      </div>
      <Row style={{ justifyContent: "space-between" }}>
        <ClientInfo>
          <Header as="h1" style={{ fontSize: "32px" }}>
            {report && report.client.name}
          </Header>
          <Header as="h2" style={{ margin: 0, color: theme.MAIN_COLOR }}>
            Status:{" "}
            {report.status === "errored" ? (
              <div style={{ maxWidth: 400 }}>
                <Header as="h4">Error: {report.error}</Header>
              </div>
            ) : (
              report.status.toUpperCase()
            )}
          </Header>
          <Header
            as="h2"
            style={{ margin: "0 0 10px 0", color: theme.MAIN_COLOR }}
          >
            Timezone: ({report && report.client.timezone})
          </Header>
          {report && report.check.number && (
            <Header
              as="h2"
              style={{ margin: "0 0 10px 0", color: theme.MAIN_COLOR }}
            >
              Check Number: {report && report.check.number}
            </Header>
          )}
          {report && report.check.url && (
            <Header
              as="h2"
              style={{ margin: "0 0 10px 0", color: theme.MAIN_COLOR }}
            >
              Check Image:{" "}
              <a
                href={report && report.check.url}
                rel="noreferrer"
                target="_blank"
              >
                Link
              </a>
            </Header>
          )}
          <Row
            style={{
              margin: "auto 0 30px 0",
              position: "absolute",
              top: "65px",
              right: "120px",
            }}
          >
            {report && (
              <NotesModal notes={report.notes} type="report" id={report._id} />
            )}
            <Popup
              position="top center"
              content={
                <Header>
                  Looks for and adds time entries that were added after this
                  report was generated
                </Header>
              }
              trigger={
                <Button
                  color="blue"
                  onClick={() => pullEntries(report._id)}
                  loading={pulling}
                  disabled={pulling}
                >
                  <Icon name="list" />
                  Pull In Time Entries
                </Button>
              }
            />
          </Row>
        </ClientInfo>
        <TotalsSection
          getAmount={getAmount}
          setState={setState}
          state={state}
          props={props}
          report={report}
          currUser={currUser}
        />
      </Row>
      <Grid stretched>
        <Header
          as="h4"
          style={{
            color: "yellow",
            backgroundColor: theme.BUTTON_COLOR_GREY,
            padding: "3px",
            borderRadius: "20px",
            textAlign: "right",
            margin: 0,
          }}
        >
          {report.client.subtractLunch &&
            "Half an hour is being subtracted for lunch. To turn this off go to client settings"}
        </Header>
        <Grid.Row>
          <Grid.Column width={3}>
            <div
              style={{
                maxHeight: 700,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {getPanes(sheets).map((pane, i) => (
                <UserOption
                  onClick={() => setState({ ...state, currUser: i, page: 1 })}
                  selected={currUser === i}
                >
                  {pane.menuItem}
                </UserOption>
              ))}
            </div>
          </Grid.Column>
          <Grid.Column width={13}>
            {currUser !== 0 ? (
              currUser !== 1 ? (
                <Table
                  loading={loading}
                  onClick={(row) =>
                    setState({
                      ...state,
                      selected: { ...row, client: report.client },
                    })
                  }
                  data={report ? paginate(sheets[currUser - 2].times) : []}
                  pagination={{
                    page,
                    limit,
                    total: sheets[currUser - 2].times.length,
                  }}
                  onPage={(page) =>
                    setState({ ...state, page: page }, () =>
                      props.refresh(limit, page, sortBy, direction)
                    )
                  }
                  onLimit={(limit) =>
                    setState({ ...state, limit: limit, page: 1 }, () =>
                      props.refresh(limit, page, sortBy, direction)
                    )
                  }
                  headers={getHeaders(false)}
                  onSort={onSort}
                  sortBy={sortBy}
                  sortDirection={direction}
                />
              ) : (
                <Table
                  loading={loading}
                  onClick={(row) => setState({ ...state, adjust: row })}
                  data={report ? paginate(report.adjustments) : []}
                  pagination={{
                    page,
                    limit,
                    total: report.adjustments.length,
                  }}
                  onPage={(page) =>
                    setState({ ...state, page: page }, () =>
                      props.refresh(limit, page, sortBy, direction)
                    )
                  }
                  onLimit={(limit) =>
                    setState({ ...state, limit: limit, page: 1 }, () =>
                      props.refresh(limit, page, sortBy, direction)
                    )
                  }
                  headers={adjustmentHeaders}
                  onSort={onSort}
                  sortBy={sortBy}
                  sortDirection={direction}
                />
              )
            ) : (
              <Table
                loading={loading}
                onClick={(row) =>
                  setState({
                    ...state,
                    selected: { ...row, client: report.client },
                  })
                }
                data={report ? paginate(viewAll(sheets)) : []}
                pagination={{ page, limit, total: viewAll(sheets).length }}
                onPage={(page) =>
                  setState({ ...state, page: page }, () =>
                    props.refresh(limit, page, sortBy, direction)
                  )
                }
                onLimit={(limit) =>
                  setState({ ...state, limit: limit, page: 1 }, () =>
                    props.refresh(limit, page, sortBy, direction)
                  )
                }
                headers={getHeaders(true)}
                onSort={onSort}
                sortBy={sortBy}
                sortDirection={direction}
              />
            )}
            <Time
              report={true}
              selected={selected}
              onClose={() => {
                setState((old) => ({ ...old, selected: null }));
                props.refresh();
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal
        open={!!message}
        closeIcon
        onClose={() => {
          setState({ ...state, message: null }, props.refresh);
        }}
        style={{
          maxWidth: 500,
          textAlign: "center",
          padding: "1%",
        }}
      >
        <Header
          as="h2"
          style={{ backgroundColor: Theme.PAGE_BACKGROUND, color: "white" }}
        >
          {message}
        </Header>
      </Modal>
      <Adjustment
        report={report}
        edit={adjust}
        onClose={() => {
          setState({ ...state, adjust: null }, props.refresh);
        }}
      />
    </div>
  );
};
export default ViewReport;
