import React, { useEffect, useRef, useState } from "react";
import { Button, Popup } from "semantic-ui-react";
import styled from "styled-components";
import { Parser } from "json2csv";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExportCSV = ({ data, filename }) => {
  const [file, setFile] = useState("");
  const exportCSV = () => {
    const parser = new Parser();
    const csv = parser.parse(data);
    const blob = new Blob([csv]);
    setFile(URL.createObjectURL(blob));
  };
  const ref = useRef(null);

  useEffect(() => {
    if (file) ref.current.click();
  }, [file]);
  return (
    <>
      <Popup
        content="Export the current table view as a csv"
        trigger={
          <Button
            style={{ height: 30 }}
            icon
            compact
            circular
            color="blue"
            onClick={() => exportCSV()}
          >
            Export CSV
          </Button>
        }
      />
      <a
        style={{ display: "none" }}
        download={`${filename}.csv`}
        href={file}
        ref={ref}
      />
    </>
  );
};
export default ExportCSV;
