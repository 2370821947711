import moment from "moment-timezone";

function sheetTotals(report) {
  let totals = [];
  report.timesheets.map((report) => {
    let curTotal = {
      hours: 0,
      paid: 0,
      overtimePaid: 0,
      overtimeHours: 0,
      billed: 0,
      overtimeBilled: 0,
      profit: 0,
    };
    report.times.sort((a, b) => (a["end"] < b["end"] ? -1 : 1));
    let firstOver = true;
    report.times.map((time) => {
      let diff = moment
        .duration(moment(time.end).diff(moment(time.start)))
        .asHours();
      curTotal.hours += diff;
      if (curTotal.hours <= 40) {
        curTotal.paid += parseFloat(diff * (time.payRate / 100));
        curTotal.billed += parseFloat(diff * (time.billRate / 100));
      } else {
        curTotal.overtimeBilled += parseFloat(
          (firstOver ? curTotal.hours - 40 : diff) *
            ((time.overtimeBillRate - time.billRate) / 100)
        );
        curTotal.overtimePaid += parseFloat(
          (firstOver ? curTotal.hours - 40 : diff) *
            ((time.overtimePayRate - time.payRate) / 100)
        );
        curTotal.paid += parseFloat(
          diff * ((time.overtimePayRate - time.payRate) / 100)
        );

        curTotal.billed += parseFloat(
          diff * ((time.overtimeBillRate - time.billRate) / 100)
        );
        firstOver = false;
      }
    });
    curTotal.profit = curTotal.billed - curTotal.paid;
    if (curTotal.hours > 40) {
      curTotal.overtimeHours = curTotal.hours - 40;
    }
    totals.push({ user: report.user, total: curTotal });
  });
  return totals;
}

export default sheetTotals;
