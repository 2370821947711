import React, { useState } from "react";
import { Button, Grid, Image, Header } from "semantic-ui-react";
import Logo from "../assets/logos/full.png";
import Theme from "../Theme.js";
import { ProfileButton, PermissionDenied } from "./";
import Settings from "./Settings.js";
import BugReport from "./BugReport.js";
import { useHistory } from "react-router";
import cookie from "js-cookie";
import axios from "axios";
import { useWindowDimensions } from "hooks";
import styled from "styled-components";
import theme from "../Theme.js";
import { version } from "../globalResources/constants";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "store/slices/userSlice";

const routes = [
  {
    name: "Home",
    link: "/home",
  },
  {
    name: "Users",
    link: "/users",
  },
  { name: "Groups", link: "/groups" },
  {
    name: "Time Entries",
    link: "/timesheets",
  },
  {
    name: "Clients",
    link: "/clients",
  },
  {
    name: "Accounting",
    link: "/accounting",
  },
  {
    name: "Analytics",
    link: "/analytics",
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const Bar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 6vh;
  background-color: ${theme.BLACK};
  justify-content: flex-end;
`;

const NavButton = styled.div`
  display: flex;
  background-color: ${theme.MAIN_COLOR};
  box-sizing: border-box;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid black;
  width: 15vw;
  justify-content: center;
  cursor: pointer;
`;

const NavText = styled.div`
  font-size: 10px;
  font-weight: 700;
  color: white;
  margin: auto 4px auto 4px;
  text-align: center;
`;

const LogoImg = styled.img`
  height: 100px;
  @media (max-width: 800px) {
    display: none;
  }
`;

const NavBar = ({ clean, children, permission }) => {
  const [settingsPermission, setSettingsPermission] = useState(null);
  const [open, setOpen] = useState(false);
  const [bug, setBug] = useState(false);
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const history = useHistory();

  const fetchCurrentUser = () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
      "token"
    )}`;
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/current`)
      .then((res) => {
        dispatch(setUser({ ...res.data.user }));
        setOpen(true);
      })
      .catch((err) => console.error(err));
  };
  const options = [
    {
      label: "Settings",
      onClick: fetchCurrentUser,
    },
    {
      label: "Report A Bug",
      onClick: () => setBug(true),
    },
    {
      label: "Logout",
      onClick: async () => {
        await cookie.remove("token");
        window.location.reload();
      },
    },
  ];
  if (isMobile)
    return (
      <Container>
        <Bar>
          {routes.map((route, index) => {
            return (
              <NavButton
                onClick={() =>
                  window.location.href.includes(route.link)
                    ? null
                    : history.push(route.link)
                }
                disabled={window.location.href.includes(route.link)}
                key={index}
              >
                <NavText>{route.name}</NavText>
              </NavButton>
            );
          })}
          <ProfileButton options={options} />
        </Bar>
        <Row
          centered
          style={{ backgroundColor: Theme.BLACK, minHeight: "91vh" }}
        >
          <Column
            style={
              !clean
                ? {
                    margin: "10px auto auto auto",
                    width: "100vw",
                    borderRadius: 10,
                    overflowX: "hidden",
                  }
                : {}
            }
          >
            {children}
          </Column>
        </Row>
        <Settings open={open} data={user} onClose={() => setOpen(false)} />
        <BugReport open={bug} onClose={() => setBug(false)} />
        <PermissionDenied
          permission={permission || settingsPermission}
          onClose={
            permission
              ? () => history.push("/")
              : () => setSettingsPermission(null)
          }
        />
      </Container>
    );
  else
    return (
      <Grid
        style={{
          padding: 0,
          margin: 0,
          width: "100vw",
          marginTop: "-2vh",
        }}
      >
        <Grid.Row
          columns={14}
          style={{
            minHeight: 80,
          }}
        >
          <Grid.Column width={2} style={{ display: isMobile ? "none" : null }}>
            <LogoImg
              style={{
                minHeight: 50,
                minWidth: 70,
                margin: "1vh auto",
              }}
              src={Logo}
            />
          </Grid.Column>
          <Grid.Column
            width={12}
            style={{
              margin: "0 -5% auto auto",
            }}
          >
            <div
              style={{
                backgroundColor: Theme.BLACK,
                paddingBottom: ".5vh",
                paddingLeft: "1vw",
                display: " flex",
                flexDirection: "row",
              }}
            >
              {routes.map((route, index) => {
                return (
                  <Button
                    onClick={() => history.push(route.link)}
                    color="blue"
                    disabled={window.location.href.includes(route.link)}
                    key={index}
                    style={{
                      fontSize: "1.4vw",
                      minHeight: 20,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  >
                    {route.name}
                  </Button>
                );
              })}
              <ProfileButton options={options} />
            </div>

            <Grid.Row
              style={{
                backgroundColor: Theme.MAIN_COLOR,
                marginLeft: "auto",
                minHeight: 15,
                borderBottomLeftRadius: "20px",
                display: "flex",
              }}
            >
              <Header
                size="small"
                style={{
                  display: "flex",
                  marginTop: "auto",
                  marginLeft: "auto",
                  marginRight: "8%",
                  color: "white",
                }}
              >
                {cookie.get("name")}
                <Header style={{ fontSize: 8 }}>{version}</Header>
              </Header>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          centered
          style={{ backgroundColor: Theme.BLACK, minHeight: "89.5vh" }}
        >
          <Grid.Column
            style={
              !clean
                ? {
                    margin: "10px auto auto auto",
                    width: "97%",
                    borderRadius: 10,
                  }
                : {}
            }
          >
            {children}
          </Grid.Column>
        </Grid.Row>
        <Settings open={open} data={user} onClose={() => setOpen(false)} />
        <BugReport open={bug} onClose={() => setBug(false)} />
        <PermissionDenied
          permission={permission || settingsPermission}
          onClose={
            permission
              ? () => history.push("/")
              : () => setSettingsPermission(null)
          }
        />
      </Grid>
    );
};
export default NavBar;
