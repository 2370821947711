import jsCookie from "js-cookie";
export { default as sheetTotals } from "./sheetTotals";

export const clearCookies = async (noToken) => {
  if (!noToken) await jsCookie.remove("token");
  await jsCookie.remove("type");
  await jsCookie.remove("name");
  await jsCookie.remove("id");
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export const formatDollars = (amount) => {
  return (amount / 100).toFixed(2);
};
