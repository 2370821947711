import axios from "axios";
import {
  Selector,
  SelectorSearch,
  Input,
  Modal,
  Button,
  ToggleButton,
  FormRow,
  FormColumn,
  FormHeader,
  Item,
  ItemList,
} from "globalComponents";
import RateModal from "globalComponents/RateModal";
import { errorHandler, getClientPermissions } from "globalResources/util";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { getTheme } from "Theme";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
`;

const Title = styled.h3``;

const defaultForm = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  language: "english",
  accountType: "employee",
  employmentType: "W2",
  pin: "",
  subLunch: "",
  groups: [],
  clients: [],
  rates: [],
  clientPermissions: ["reports", "approve", "timeentries"],
};

const defaultErrors = {
  ...defaultForm,
  language: "",
  accountType: "",
  employmentType: "",
  groups: "",
  clients: "",
  clientPermissions: "",
};

const defaultRate = {
  client: "",
};

const UserModal = ({ onClose, selected }) => {
  const [form, setForm] = useState(defaultForm);
  const [errors, setErrors] = useState(defaultErrors);
  const [groupSuggestions, setGroupSuggestions] = useState([]);
  const [clientSuggestions, setClientSuggestions] = useState([]);
  const [rate, setRate] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!selected) return;
    if (selected._id) setForm({ ...selected });
    else setForm(defaultForm);
  }, [selected]);

  useEffect(() => {
    getGroupSuggestions();
    getClientSuggestions();
  }, []);

  const user = useSelector((state) => state.current.user);
  const userPermissions = useSelector((state) => state.current.permissions);

  const treatClient = user.accountType === "client";

  const getAccountTypeOptions = () => {
    let options = [
      { label: "Employee", value: "employee" },
      { label: "Client", value: "client" },
    ];

    if (user.accountType === "super")
      return options.concat([
        { label: "Super", value: "super" },
        { label: "Admin", value: "admin" },
      ]);
    else if (user.accountType === "admin")
      return options.concat([{ label: "Admin", value: "admin" }]);
    else return options;
  };

  const getGroupSuggestions = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_API_URL}/group/suggestions`
      );
      let options = [];

      res.data.groups.map((group) =>
        options.push(
          group.name === "All"
            ? { label: "Select a Group to add", value: "" }
            : { label: group.name, value: group._id }
        )
      );
      setGroupSuggestions(options);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getClientSuggestions = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/suggestions`
      );
      let options = [];

      res.data.map((client) =>
        options.push(
          client.name === "All"
            ? { label: "Select a Client to add", value: "" }
            : { label: client.name, value: client._id }
        )
      );
      setClientSuggestions(options);
    } catch (error) {
      errorHandler(error);
    }
  };

  const updateForm = (value, name, isDelete) => {
    if (name === "groups" && isDelete) {
      setForm((old) => ({
        ...old,
        groups: old.groups.filter((g) => g !== value),
      }));
    } else if (name === "rates") {
      let newRates = [];
      let newClients = [];
      newRates = form.rates.filter((r) => r.client !== value.client);
      newClients = form.clients.filter((c) => c !== value.client);
      if (!isDelete) {
        newRates.push({
          ...value,
          payRate: value.payRate * 100,
          billRate: value.billRate * 100,
          overtimePayRate: value.overtimePayRate * 100,
          overtimeBillRate: value.overtimeBillRate * 100,
        });
        newClients.push(value.client);
      }
      setForm((old) => ({
        ...old,
        rates: newRates,
        clients: newClients,
      }));
      setRate(null);
    } else {
      setForm((old) => ({ ...old, [name]: value }));
      setErrors((old) => ({ ...old, [name]: "" }));
    }
  };

  const saveUser = () => {
    setLoading(true);
    axios[selected._id ? "patch" : "post"](
      `${process.env.REACT_APP_API_URL}/users/`,
      {
        ...form,
        id: selected._id,
      }
    )
      .then((res) => {
        setForm(defaultForm);
        setErrors(defaultErrors);
        onClose();
      })
      .catch((err) => {
        setErrors((old) => ({ ...old, general: "Error saving user" }));
      })
      .finally(() => setLoading(false));
  };

  const toggleDisable = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/archive`, {
        id: selected._id,
      })
      .then((res) => {
        setForm(defaultForm);
        setErrors(defaultErrors);
        onClose();
      })
      .catch(async (error) => {
        let { message } = await errorHandler(error);
        setErrors((old) => ({
          ...old,
          general: "Error archiving user: " + message,
        }));
      })
      .finally(() => setLoading(false));
  };

  const getByValue = (value, list) => {
    return list.find((item) => item.value === value);
  };

  if (!selected) return null;

  return (
    <Modal onClose={onClose}>
      <Main>
        <FormRow>
          <Input
            label="First Name"
            value={form.firstName}
            onChange={(e) => updateForm(e.currentTarget.value, "firstName")}
            error={errors.firstName}
          />
          <Input
            label="Last Name"
            value={form.lastName}
            onChange={(e) => updateForm(e.currentTarget.value, "lastName")}
            error={errors.lastName}
          />
        </FormRow>
        <FormRow>
          <Input
            label="Email"
            type="email"
            value={form.email}
            onChange={(e) => updateForm(e.currentTarget.value, "email")}
            error={errors.email}
          />
          <Input
            label="Phone Number"
            type="phone"
            value={form.phone}
            onChange={(e) => updateForm(e.currentTarget.value, "phone")}
            error={errors.phone}
          />
        </FormRow>
        <FormRow>
          <Selector
            label="Language"
            options={[
              { label: "English", value: "english" },
              { label: "Spanish", value: "spanish" },
            ]}
            value={form.language}
            onSelect={(v) => updateForm(v, "language")}
            error={errors.language}
          />
          <Selector
            label="Account Type"
            options={getAccountTypeOptions()}
            value={form.accountType}
            onSelect={(v) => updateForm(v, "accountType")}
            error={errors.accountType}
          />
        </FormRow>
        <FormRow>
          <Selector
            label="Employment Type"
            value={form.employmentType}
            onSelect={(v) => updateForm(v, "employmentType")}
            error={errors.employmentType}
            options={[
              { label: "W2", value: "W2" },
              { label: "1099", value: "1099" },
            ]}
          />
          <Input
            label="Timestation Pin Number"
            type="number"
            value={form.pin}
            onChange={(e) => updateForm(e.currentTarget.value, "pin")}
            error={errors.pin}
          />
        </FormRow>
        <FormRow>
          {form.accountType === "client" ? (
            <FormColumn>
              <FormHeader>Client Permissions</FormHeader>
              <ItemList>
                {getClientPermissions().map((permission) => (
                  <Item>
                    <ToggleButton
                      value={form.clientPermissions.includes(permission.value)}
                      onChange={() =>
                        updateForm(
                          !form.clientPermissions.includes(permission.value)
                            ? form.clientPermissions.concat([permission.value])
                            : form.clientPermissions.filter(
                                (p) => p !== permission.value
                              ),
                          "clientPermissions"
                        )
                      }
                    />
                    {permission.label}
                  </Item>
                ))}
              </ItemList>
            </FormColumn>
          ) : (
            <FormColumn>
              <SelectorSearch
                fillSearch={false}
                value=""
                label="Add to Group"
                onSelect={(v) => updateForm(form.groups.concat([v]), "groups")}
                options={
                  groupSuggestions.filter(
                    (g) => g.label !== "All" && !form.groups.includes(g.value)
                  ) || []
                }
              />
              <ItemList>
                {form.groups.map(
                  (g) =>
                    getByValue(g, groupSuggestions) && (
                      <Item onClick={() => updateForm(g, "groups", true)}>
                        {getByValue(g, groupSuggestions).label}
                      </Item>
                    )
                )}
              </ItemList>
            </FormColumn>
          )}
          <FormColumn>
            <Title>Clients (Edit from the client details)</Title>
            <ItemList>
              {form.clients.map(
                (c) =>
                  getByValue(c, clientSuggestions) && (
                    <Item
                      onClick={() =>
                        history.push(
                          `/client/${
                            getByValue(c, clientSuggestions).value
                          }/details`
                        )
                      }
                    >
                      {getByValue(c, clientSuggestions).label}
                    </Item>
                  )
              )}
            </ItemList>
          </FormColumn>
        </FormRow>
        <FormRow>
          <Button onClick={toggleDisable} color={theme.BUTTON_COLOR_GREY}>
            {selected.isArchived ? "Enable" : "Disable"} User
          </Button>
          <Button onClick={saveUser}>Save Changes</Button>
        </FormRow>
      </Main>
      <RateModal
        rate={rate}
        setRate={setRate}
        onDelete={() => updateForm(rate, "rates", true)}
        onSave={() => updateForm(rate, "rates")}
      />
    </Modal>
  );
};
export default UserModal;
