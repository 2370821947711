import axios from "axios";
import { Button, InfiniteTable, Row } from "globalComponents";
import { formatDollars } from "globalResources/helpers";
import { errorHandler } from "globalResources/util";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import EmployeeModal from "./EmployeeModal";

const Main = styled.div`
	display: flex;
	flex-direction: column;
`;

const Title = styled.h3`
	font-weight: 600;
	font-size: 20px;
	margin: 0;
`;

const headers = [
	{
		label: "Name",
		render: row => <div>{row.user.firstName + " " + row.user.lastName}</div>,
	},
	{
		label: "Position",
		render: row => <div>{row.position}</div>,
	},
	{
		label: "Bill Rate",
		render: row => (
			<div>
				${formatDollars(row.billRate)} (${formatDollars(row.overtimeBillRate)})
			</div>
		),
	},
	{
		label: "Pay Rate",
		render: row => (
			<div>
				${formatDollars(row.payRate)} (${formatDollars(row.overtimePayRate)})
			</div>
		),
	},
];

const EmployeeList = ({ employees = [], client, updateDetails }) => {
	const [employee, setEmployee] = useState();

	const update = (employee, action) => {
		let temp = client;

		if (action === "add") temp.employees.push(employee);
		else if (action === "update")
			temp.employees.forEach((e, i) => {
				if (
					e.user === employee.user &&
					employee.previousPosition === e.position
				) {
					temp.employees[i] = employee;
				}
				return;
			});
		else
			temp.employees = temp.employees.filter(
				e =>
					e.user !== employee.user || employee.previousPosition !== e.position
			);

		axios
			.patch("/client", { ...temp, id: temp._id })
			.then(res => updateDetails(res.data))
			.catch(async error => {
				const { message } = await errorHandler(error);
			});
	};

	return (
		<Main>
			<Row style={{ justifyContent: "space-between", padding: "10px 0" }}>
				<Title>Employees ({employees.length})</Title>
				<Button width="150px" onClick={() => setEmployee({})}>
					+ Add Employee
				</Button>
			</Row>
			<InfiniteTable
				height="400px"
				data={employees.sort((a, b) =>
					a.user.firstName + a.user.lastName >
					b.user.firstName + b.user.lastName
						? 1
						: -1
				)}
				headers={headers}
				onClick={row => setEmployee(row)}
			/>
			<EmployeeModal
				employees={employees}
				employee={employee}
				onClose={() => setEmployee(null)}
				onSubmit={(employee, action) => update(employee, action)}
			/>
		</Main>
	);
};
export default EmployeeList;
