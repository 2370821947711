import { Row } from "globalComponents";
import React from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
const theme = getTheme();

const Container = styled.div`
  min-height: 600px;
`;

const FolderMarkLeft = styled.div`
  border-bottom: 40px solid ${theme.MANILLA};
  border-left: 15px solid transparent;
  margin-left: 20px;
  margin-right: -2px;
`;
const FolderMarkRight = styled.div`
  border-bottom: 40px solid ${theme.MANILLA};
  border-right: 15px solid transparent;
  margin-left: -2px;
`;

const FolderLabel = styled.div`
  background: ${theme.MANILLA};
  font-size: 22px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  width: 30%;
  text-align: center;
  padding: 10px;
  margin-bottom: -2px;
  border-radius: 5px;
  @media (max-width: 800px) {
    width: 200px;
  }
`;

const ChildrenContainer = styled.div`
  border-radius: 20px;
  background: ${theme.MANILLA};
  min-height: 400px;
  padding: 10px;
  @media (max-width: 900px) {
    border: 0;
  }
`;

const FolderContainer = ({ children, title }) => {
  return (
    <Container>
      <Row>
        <FolderMarkLeft />
        <FolderLabel>{title}</FolderLabel>
        <FolderMarkRight />
      </Row>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};
export default FolderContainer;
