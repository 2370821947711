import React, { Component } from "react";
import { Button, Icon, Header, Form } from "semantic-ui-react";
import Station from "./components/Station.js";
import Theme from "../../Theme.js";
import cookie from "js-cookie";
import axios from "axios";

class TimeStation extends Component {
	state = {
		clients: [],
		users: null,
		spanish: false,
		error: "",
	};
	fetchClients = () => {
		axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
			"token"
		)}`;
		this.setState({ loading: true });
		axios

			.get(`${process.env.REACT_APP_API_URL}/client/suggestions`)
			.then(async res => {
				if (res.data.length < 1) {
					this.setState({
						clients: [],
					});
				} else {
					const clients = res.data;
					var temp = [];
					clients.forEach(client => {
						if (client.name !== "All")
							temp.push({
								text: client.name,
								value: client,
							});
					});
					this.setState({
						clients: temp,
					});
				}
				this.setState({ loading: false });
			})
			.catch(err => this.setState({ loading: false, error: "error" }));
	};
	fetchUsers = client => {
		axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
			"token"
		)}`;
		this.setState({ loading: true });
		axios
			.get(`${process.env.REACT_APP_API_URL}/users`, {
				params: {
					search: this.state.search,
					searchBy: this.state.searchBy,
					client: this.state.client._id,
				},
			})
			.then(async res => {
				if (res.data.users && res.data.users.length < 1) {
					this.setState({ error: "Client has no users" });
				} else {
					const users = res.data.users;
					var temp = [];
					users.forEach(user => {
						temp.push({
							text: `${user.firstName} ${user.lastName}`,
							value: user,
						});
					});
					this.setState({
						users: temp,
					});
				}
				this.setState({ loading: false });
			})
			.catch(err => this.setState({ loading: false, error: "error" }));
	};
	componentDidMount() {
		this.fetchClients();
	}
	render() {
		const { loading, clients, client, users, error, spanish } = this.state;
		return (
			<>
				<Button
					style={{ margin: "auto .5vw 0 auto", position: "absolute" }}
					loading={loading}
					color="blue"
					onClick={() => {
						this.props.history.goBack();
					}}
				>
					<Icon name="left arrow" />
					Back
				</Button>
				<Header as="h1" textAlign="center">
					Time Station
				</Header>
				<Header textAlign="center">
					Setup a time station for multiple users to check in on site.
				</Header>
				<Header textAlign="center">
					For security purposes, once the time station is setup the rest of the
					app will be locked and you will have to log back in to access other
					pages.
				</Header>
				<div
					style={{
						width: "99%",
						display: "flex",
						flexDirection: "column",
						textAlign: "center",
						backgroundColor: Theme.PAGE_BACKGROUND,
						padding: "1%",
						margin: "auto auto auto auto",
						borderRadius: 5,
						minHeight: 400,
					}}
				>
					<Header
						as="h3"
						textAlign="center"
						style={{ color: "white", margin: "5vh auto 1vh auto" }}
					>
						Select the client to setup the timestation for
					</Header>
					<Form.Dropdown
						onChange={(e, v) => {
							this.setState({ client: v.value });
						}}
						style={{ margin: "auto" }}
						selection
						search
						options={clients}
					/>
					<Form.Radio
						style={{
							margin: "2vh auto auto auto",
							fontColor: "white",
							fontSize: "2vh",
						}}
						label="Spanish Prompt"
						color="white"
						checked={spanish}
						value={spanish}
						toggle
						onChange={(event, { name, value }) => {
							this.setState({ spanish: !spanish });
						}}
					/>

					<Header
						as="h3"
						color="red"
						textAlign="center"
						style={{
							color: "white",
							margin: "5vh auto 1vh auto",
						}}
					>
						{error}
					</Header>
					<Button
						style={{ width: "50%", margin: "auto auto 2vh auto" }}
						loading={loading}
						color="blue"
						onClick={() => {
							this.setState({ error: "" });
							if (client) this.fetchUsers();
							else this.setState({ error: "Must select a client" });
						}}
					>
						Start Time Station
					</Button>
				</div>
				<Station
					users={users}
					spanish={spanish}
					client={client}
					onClose={async () => {
						await cookie.remove("token");
						window.location.reload();
					}}
				/>
			</>
		);
	}
}

export default TimeStation;
