import React from "react";
import { DatePicker } from "globalComponents";
import Theme from "Theme.js";
import axios from "axios";
import cookie from "js-cookie";
import ViewReport from "./components/ViewReport";
import { Grid, Header, Radio, Button, Loader, Form } from "semantic-ui-react";
import moment from "moment-timezone";

class Analytics extends React.Component {
	state = {
		report: [],
		start: null,
		end: null,
		clients: [],
		client: null,
		billed: true,
		reported: true,
		reportType: "default",
	};

	fetchAnalytics = refresh => {
		const { start, end, client, billed, reported } = this.state;
		if (!start || !end)
			this.setState({ error: "Select a Start and an End Date" });
		else {
			this.setState({ loading: !refresh });
			axios
				.get(`${process.env.REACT_APP_API_URL}/analytic`, {
					params: {
						start,
						end,
						client,
						billed,
						reported,
					},
				})
				.then(res => {
					let temp = res.data.report;
					temp.sort((a, b) => (a.user.firstName > b.user.firstName ? 1 : -1));
					this.setState({
						report: temp,
						loading: false,
						error: res.data.report.length > 0 ? null : "No results found",
					});
				})
				.catch(error => {
					this.setState({ loading: false, error: "No results found" });
					console.error(error);
				});
		}
	};

	downloadReport = view => {
		const { start, end, clients, client, billed, reported, reportType } =
			this.state;
		if (!start || !end)
			this.setState({ error: "Select a Start and an End Date" });
		else {
			this.setState({ loading: true, error: "" });
			let clientInfo = this.state.clients.find(
				c => c.value === this.state.client
			);
			axios
				.get(`${process.env.REACT_APP_API_URL}/analytic/report/invoice`, {
					responseType: "blob",
					headers: {
						"Content-Type": "application/pdf",
					},
					params: {
						start,
						end,
						client,
						billed,
						reported,
						type: reportType,
					},
				})
				.then(({ data }) => {
					const downloadUrl = window.URL.createObjectURL(new Blob([data]));
					const link = document.createElement("a");
					link.href = downloadUrl;
					console.log(clients.find(c => c.value === client).text);
					link.setAttribute(
						"download",
						`${clientInfo.text}${
							reportType === "hours" ? "-Hours" : "-Analytics"
						}-report-${
							moment(start).format("L") + " - " + moment(end).format("L")
						}.pdf`
					); //any other extension
					document.body.appendChild(link);
					link.click();
					link.remove();

					this.setState({ loading: false });
				})
				.catch(error => {
					console.error("File could not be downloaded:", error);
				});
		}
	};

	fetchClients() {
		axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
			"token"
		)}`;
		axios
			.get(`${process.env.REACT_APP_API_URL}/client/suggestions`)
			.then(async res => {
				if (res.data.length < 1) {
					this.setState(
						{
							clients: [{ text: "No Clients", value: "none" }],
						},
						this.fetchTimesheets
					);
				} else {
					var clients = res.data;
					var temp = [];
					clients.forEach(client => {
						temp.push({ text: client.name, value: client._id });
					});
					this.setState(
						{
							clients: temp,
							client: temp[0].value,
						},
						this.fetchTimesheets
					);
				}
			})
			.catch(err =>
				this.setState({ error: "Failed to get client suggestions" })
			);
	}

	calculateReportTotals(reports) {
		let totals = [];

		reports.forEach(report => {
			let curTotal = {
				hours: 0,
				paid: 0,
				overtimePaid: 0,
				overtimeHours: 0,
				billed: 0,
				overtimeBilled: 0,
				profit: 0,
			};
			report.times.sort((a, b) => (a["end"] < b["end"] ? -1 : 1));
			let firstOver = true;
			report.times.forEach(time => {
				let diff = moment
					.duration(moment(time.end).diff(moment(time.start)))
					.asHours();
				if (time.client.subtractLunch && !report.user.overrideLunch)
					diff -= 0.5;
				curTotal.hours += diff;
				if (curTotal.hours <= 40) {
					curTotal.paid += parseFloat(diff * (time.payRate / 100));
					curTotal.billed += parseFloat(diff * (time.billRate / 100));
				} else {
					curTotal.overtimeBilled += parseFloat(
						(firstOver ? curTotal.hours - 40 : diff) *
							((time.overtimeBillRate - time.billRate) / 100)
					);
					curTotal.overtimePaid += parseFloat(
						(firstOver ? curTotal.hours - 40 : diff) *
							((time.overtimePayRate - time.payRate) / 100)
					);
					curTotal.paid += parseFloat(
						diff * ((time.overtimePayRate - time.payRate) / 100)
					);

					curTotal.billed += parseFloat(
						diff * ((time.overtimeBillRate - time.billRate) / 100)
					);
					firstOver = false;
				}
			});
			curTotal.profit = curTotal.billed - curTotal.paid;
			if (curTotal.hours > 40) {
				curTotal.overtimeHours = curTotal.hours - 40;
			}
			totals.push({ user: report.user, total: curTotal });
		});
		return totals;
	}

	componentDidMount() {
		this.fetchClients();
	}

	render() {
		const {
			report,
			start,
			end,
			loading,
			error,
			client,
			clients,
			billed,
			reported,
			reportType,
		} = this.state;
		return (
			<>
				{(client === "all" || moment(end).diff(moment(start), "days") > 7) && (
					<Header color="yellow" as="h5" style={{ margin: "0 10px" }}>
						Reports spanning long date ranges and/or including all clients may
						take a while to load and also update when a time entry is edited
					</Header>
				)}
				<Grid style={{ margin: ".2%", backgroundColor: Theme.PAGE_BACKGROUND }}>
					<Grid.Row columns={4}>
						<Grid.Column>
							<Header as="h3" style={{ color: "white" }}>
								Start Date
							</Header>
							<DatePicker
								type="date"
								max={moment(end).format("YYYY-MM-DD")}
								onChange={v => {
									this.setState({ start: v });
								}}
								onFirst={v =>
									!end &&
									this.setState({
										end: moment(v).add(6, "days").format("YYYY-MM-DD"),
									})
								}
								value={moment(start).format("YYYY-MM-DD")}
							/>
						</Grid.Column>

						<Grid.Column>
							<Header as="h3" style={{ color: "white" }}>
								End Date
							</Header>
							<DatePicker
								type="date"
								mnin={moment(start).format("YYYY-MM-DD")}
								onChange={v => this.setState({ end: v })}
								value={moment(end).format("YYYY-MM-DD")}
							/>
						</Grid.Column>
						<Grid.Column style={{ paddingTop: 15 }}>
							<Header as="h4" style={{ color: "white" }}>
								Client
							</Header>
							<Form.Dropdown
								onChange={(event, { name, value }) => {
									this.setState({ client: value });
								}}
								search
								selection
								value={client}
								options={clients}
							/>
						</Grid.Column>
						<Grid.Column style={{ textAlign: "center", paddingTop: 15 }}>
							<Header as="h4" style={{ color: "white" }}>
								Download Type
							</Header>
							<Form.Dropdown
								onChange={(event, { name, value }) => {
									this.setState({ reportType: value });
								}}
								search
								selection
								value={reportType}
								options={[
									{ value: "default", text: "Analytics Report" },
									{ value: "hours", text: "Hours Only Report" },
								]}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row
						style={{
							alignItems: "center",
							textAlign: "center",
							padding: "0 2vw",
						}}
					>
						<div style={{ margin: "auto 1em auto 0" }}>
							<Header as="h4" style={{ color: "white", marginBottom: 0 }}>
								Include Reported Times
							</Header>
							<Radio
								checked={reported}
								toggle
								style={{ textColor: "white", margin: ".5em auto auto auto" }}
								onChange={() =>
									this.setState({
										reported: !reported,
										billed: reported ? false : billed,
									})
								}
							/>
						</div>
						<div style={{ marginLeft: "2em" }}>
							<Header as="h4" style={{ color: "white", marginBottom: 0 }}>
								Include Billed Times
							</Header>
							<Radio
								checked={billed}
								toggle
								style={{ textColor: "white", margin: ".5em auto auto auto" }}
								onChange={() =>
									this.setState({
										billed: !billed,
										reported: !billed ? true : reported,
									})
								}
							/>
						</div>
						<Button
							floated="right"
							style={{ margin: "2vh 2vw 2vh auto" }}
							onClick={() => this.fetchAnalytics()}
							color="blue"
						>
							Generate Report
						</Button>
						<Button
							floated="right"
							style={{ margin: "2vh 1vw" }}
							color="blue"
							onClick={() => {
								this.downloadReport();
							}}
							loading={this.loading}
						>
							Download Report
						</Button>
					</Grid.Row>
					<Grid.Row style={{ minHeight: "40vh" }}>
						{loading ? (
							<Loader active size="huge" margin="5% auto 5% auto" inverted />
						) : report.length < 1 ? (
							<Header color="red" style={{ marginLeft: 10 }}>
								{error}
							</Header>
						) : (
							<ViewReport
								totals={this.calculateReportTotals(report)}
								report={report}
								showClient={client == "all"}
								downloadReport={this.downloadReport}
								loading={this.state.loading}
								refresh={(limit, page) => this.fetchAnalytics(true)}
							/>
						)}
					</Grid.Row>
				</Grid>
			</>
		);
	}
}
export default Analytics;
