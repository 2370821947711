import axios from "axios";
import {
  Button,
  FolderContainer,
  SearchBar,
  Table,
  ToggleButton,
} from "globalComponents";
import GroupEdit from "pages/admin/forms/GroupEdit";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Group from "../admin/forms/Group";

const headers = [
  {
    label: "Name",
    sortBy: "name",
    render: (row) => <div>{row.name}</div>,
  },
  { label: "Description", render: (row) => <div>{row.description}</div> },
];
const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
`;

const CreateButton = styled(Button)`
  margin-left: auto;
  width: 130px;
  position: absolute;
  right: 10px;
  font-weight: 600;
`;

const Groups = () => {
  const currentUser = useSelector((state) => state.current.user);
  const [search, setSearch] = useState();
  const [groups, setGroups] = useState([]);
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
    total: 0,
  });
  const [sort, setSort] = useState({ sort: "name", direction: 1 });
  const [showDisabled, setShowDisabled] = useState(false);

  useEffect(() => {
    if (!selected) {
      setLoading(true);
      axios
        .get("/groups", {
          params: { search, ...sort, ...pagination, isArchived: showDisabled },
        })
        .then((res) => {
          setGroups(res.data.groups);
          setPagination((old) => ({ ...old, total: res.data.total }));
        })
        .finally(() => setLoading(false));
    }
  }, [search, pagination.page, pagination.limit, selected, sort, showDisabled]);

  const getSelected = (id) => {
    setLoading(true);
    axios
      .get("/group", { params: { id } })
      .then((res) => setSelected({ ...res.data.group, team: res.data.team }))
      .finally(() => setLoading(false));
  };

  return (
    <Main>
      <CreateButton onClick={() => setSelected({})}>
        + Create Group
      </CreateButton>
      <FolderContainer title="Groups">
        <SearchBar
          search={search}
          onSearch={setSearch}
          style={{ margin: "10px auto", width: "50%" }}
        />{" "}
        <ToggleButton
          label="Show Disabled"
          onChange={() => setShowDisabled(!showDisabled)}
          value={showDisabled}
        />
        <Table
          data={groups}
          loading={loading}
          headers={headers}
          pagination={pagination}
          onClick={(row) => getSelected(row._id)}
          onPage={(page) => setPagination((old) => ({ ...old, page }))}
          onLimit={(limit) => {
            console.log("limit: ", limit);
            setPagination((old) => ({ ...old, limit }));
          }}
          sortBy={sort.sort}
          sortDirection={sort.direction}
          onSort={(name, direction) => setSort({ sort: name, direction })}
        />
      </FolderContainer>
      <GroupEdit selected={selected} onClose={() => setSelected(null)} />
    </Main>
  );
};
export default Groups;
