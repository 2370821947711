import { clearCookies } from "./helpers";

export const errorHandler = async (error) => {
  let message = "";
  let action = "";
  if (error.response)
    switch (error.response.status) {
      case 400:
        message = "Provided values are invalid";
        action = "checkInput";
        break;
      case 401:
        //logout
        await clearCookies();
        window.location.replace("/home");
        break;
      case 403:
        message = "You do not have permission to perform this action";
        break;
      case 404:
        message = error.response.data || "Not Found";
        break;
      case 405:
        message = error.response.data;
        break;
      default:
        break;
    }
  return { message, action };
};

export const getClientPermissions = () => {
  return [
    {
      label: "Ability to edit/create users at their locations",
      value: "users",
    },
    { label: "Ability to see Reports section and invoices", value: "reports" },
    { label: "Ability to approve reports", value: "approve" },
    { label: "Ability to view the analytics section", value: "analytics" },
    { label: "Ability to edit time entries", value: "timeentries" },
  ];
};

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

export function formatRate(rate) {
  return `$${(rate / 100).toFixed(2)}`;
}
