import axios from "axios";
import { errorHandler } from "globalResources/util";
import React from "react";
import { useSelector } from "react-redux";
import { Button, Icon, Header, Grid } from "semantic-ui-react";
import styled from "styled-components";
import moment from "moment-timezone";
import { useState } from "react";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  width: 550px;
  margin-left: "auto";
  gap: 5px;
`;
const TotalRow = styled.div`
  display: flex;
  justify-items: space-between;
  width: 100%;
`;

const TotalLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
`;
const Total = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-left: auto;
`;

const TotalsSection = ({
  getAmount,
  setState,
  props,
  state,
  report,
  currUser,
}) => {
  const [downloading, setDownloading] = useState(false);
  const currentUser = useSelector((state) => state.current.user);
  const treatClient = currentUser.accountType === "client";

  const totals = [
    {
      label: "Total Hours: ",
      value: `${getAmount(currUser).hours.toFixed(2)} hours`,
    },
    {
      label: "Overtime Hours: ",
      value: `${getAmount(currUser).overtimeHours.toFixed(2)} hours`,
    },
    {
      label: "Overtime Paid: ",
      value: `$${getAmount(currUser).overtimePaid.toFixed(2)}`,
      nonClient: true,
    },
    {
      label: "Overtime Billed: ",
      value: `$${getAmount(currUser).overtimeBilled.toFixed(2)}`,
    },
    {
      label: "Total Employee Pay: ",
      value: `$${(
        getAmount(currUser).paid + getAmount(currUser).overtimePaid
      ).toFixed(2)}`,
      nonClient: true,
    },
    {
      label: "Total Billed Amount: ",
      value: `$${(
        getAmount(currUser).billed + getAmount(currUser).overtimeBilled
      ).toFixed(2)}`,
    },
    {
      label: "Total Profit: ",
      value: `$${getAmount(currUser).profit.toFixed(2)}`,
      nonClient: true,
    },
  ];

  function sendReview(id) {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/${
          treatClient ? "client/" : ""
        }report/send`,
        {
          id,
        }
      )
      .then(async (res) => {
        setState({
          ...state,
          message: "Report has been sent for review",
        });
        props.refresh();
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setState({ ...state, loading: false, message });
      });
  }

  function billClient(id) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/report/bill`, {
        id: id,
      })
      .then(async (res) => {
        setState({ ...state, message: "Report has been billed to the client" });
        props.refresh();
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setState({ ...state, loading: false, message });
      });
  }

  const downloadReport = (view) => {
    setDownloading(true);
    const report = props.report;
    setState({ ...state });
    axios
      .get(`${process.env.REACT_APP_API_URL}/report/invoice`, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/pdf",
        },
        params: { id: report._id, view },
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          `${report.client.name}-${
            moment(report.start).tz(report.client.timezone).format("L") +
            " - " +
            moment(report.end).tz(report.client.timezone).format("L")
          }-invoice#${report.number}.pdf`
        ); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        setState({ ...state });
      })
      .catch((error) => {
        console.error("File could not be downloaded:", error);
        setState({
          ...state,
          message: "Error: could not download report",
        });
      })
      .finally(() => setDownloading(false));
  };

  return (
    <Main>
      {report.status === "errored" || report.status === "generated" ? (
        <div style={{ marginLeft: "auto" }}>
          <Button
            color="blue"
            floated="right"
            style={{ margin: "1vh" }}
            onClick={() => sendReview(report._id)}
            content="Send for Review"
          />
          <Button
            style={{ margin: "1vh" }}
            floated="right"
            color="blue"
            onClick={() => setState({ ...state, adjust: true })}
          >
            <Icon name="calculator" />
            Add Adjustment
          </Button>
        </div>
      ) : report.status === "in-review" ? (
        <div>
          {!treatClient && (
            <Button
              style={{ margin: "1vh", marginLeft: "auto" }}
              floated="right"
              color="blue"
              onClick={() => billClient(report._id)}
            >
              Approve Report
            </Button>
          )}
          <Button
            style={{ margin: "1vh" }}
            floated="right"
            color="blue"
            onClick={() => setState({ ...state, adjust: true })}
          >
            <Icon name="calculator" />
            Add Adjustment
          </Button>
        </div>
      ) : report.status === "client-review" ? (
        <div>
          {treatClient && (
            <Button
              style={{ margin: "1vh", marginLeft: "auto" }}
              floated="right"
              color="blue"
              onClick={() => billClient(report._id)}
            >
              Approve Report
            </Button>
          )}
          <Button
            style={{ margin: "1vh" }}
            floated="right"
            color="blue"
            onClick={() => setState({ ...state, adjust: true })}
          >
            <Icon name="calculator" />
            Add Adjustment
          </Button>
        </div>
      ) : (
        <Grid.Row>
          <Button
            style={{ margin: "1vh", marginLeft: "auto" }}
            floated="right"
            color="blue"
            loading={downloading}
            onClick={() => {
              downloadReport("compact");
            }}
          >
            Download Compact Invoice
          </Button>

          <Button
            style={{ margin: "1vh", marginLeft: "auto" }}
            floated="right"
            color="blue"
            loading={downloading}
            onClick={() => {
              downloadReport();
            }}
          >
            Download Invoice
          </Button>
        </Grid.Row>
      )}
      {totals.map((total) =>
        treatClient && total.nonClient ? null : (
          <TotalRow>
            <TotalLabel>{total.label}</TotalLabel>
            <Total>{total.value}</Total>
          </TotalRow>
        )
      )}
    </Main>
  );
};
export default TotalsSection;
