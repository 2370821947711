import axios from "axios";
import {
  Button,
  FolderContainer,
  SearchBar,
  SelectorSearch,
  Selector,
  Table,
  ToggleButton,
} from "globalComponents";
import ExportCSV from "globalComponents/ExportCSV";
import { errorHandler, formatPhoneNumber } from "globalResources/util";
import UserModal from "pages/admin/forms/UserModal";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getTheme } from "Theme";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
`;

const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px;
  gap: 20px;
  flex-wrap: wrap;
`;

const CreateButton = styled(Button)`
  margin-left: auto;
  width: 130px;
  position: absolute;
  right: 10px;
  font-weight: 600;
`;

const Error = styled.h2`
  color: ${theme.NEGATIVE};
`;

const headers = [
  {
    label: "UID",
    width: "200px",
    sortBy: "UID",
    render: (row) => row.UID,
  },
  {
    label: "First",
    sortBy: "firstName",
    render: (row) => row.firstName,
  },
  {
    label: "Last",
    sortBy: "lastName",
    render: (row) => row.lastName,
  },
  {
    label: "Email",
    sortBy: "email",
    render: (row) => row.email,
  },
  {
    label: "Phone",
    width: "500px",
    sortBy: "phone",
    render: (row) => formatPhoneNumber(row.phone),
  },
  {
    label: "Groups",
    width: "120%",
    render: (row) =>
      row.groups
        ? row.groups.map((group, i) =>
            group
              ? " " + group.name + (i + 1 === row.groups.length ? " " : ", ")
              : ""
          )
        : "",
  },
  {
    label: "Account Type",
    width: "40%",
    render: (row) => row.accountType,
  },
  {
    label: "Tax",
    width: "200px",
    render: (row) => (row.contractor ? "1099" : "W2"),
  },
];
const Users = () => {
  const currentUser = useSelector((state) => state.current.user);
  const [clients, setClients] = useState([]);
  const [groups, setGroups] = useState([]);
  const [user, setUser] = useState(null);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    limit: 20,
    page: 1,
    total: 0,
  });
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("name");
  const [showDisabled, setShowDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sort, setSort] = useState({ sort: "firstName", direction: 1 });

  useEffect(() => {
    setPagination((old) => ({ ...old, page: 1 }));
  }, [filters, search, searchBy, showDisabled]);

  const fetchUsers = () => {
    axios
      .get("/users", {
        params: {
          ...pagination,
          ...filters,
          search,
          searchBy,
          isArchived: showDisabled,
          ...sort,
        },
      })
      .then((res) => {
        setUsers(res.data.users);
        setPagination((old) => ({ ...old, total: res.data.total }));
      })
      .catch((err) => {
        const { message } = errorHandler(err);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    fetchUsers();
  }, [
    pagination.limit,
    pagination.page,
    filters,
    search,
    searchBy,
    showDisabled,
    sort,
  ]);

  useEffect(() => {
    axios.get("/client/suggestions").then((res) => {
      let temp = [];
      res.data.map(
        (client) =>
          client.name !== "All" &&
          temp.push({ label: client.name, value: client._id })
      );
      setClients(temp);
    });
  }, []);

  useEffect(() => {
    axios.get("/group/suggestions").then((res) => {
      let temp = [];
      res.data.groups.map(
        (group) =>
          group.name !== "All" &&
          temp.push({ label: group.name, value: group._id })
      );
      setGroups(temp);
    });
  }, []);

  const format4CSV = () => {
    let ans = [];
    users.map((row) => {
      ans.push({
        UID: row.UID,
        firstName: row.firstName,
        lastName: row.lastName,
        phone: row.phone,
        email: row.email,
        groups: row.groups.map((group, index) => {
          if (!group) return;
          if (index === row.groups.length - 1) return group.name;
          else return group.name + " / ";
        }),
        type: row.accountType,
        tax: row.employeeType,
      });
    });
    return ans;
  };

  const getSelectedUser = (id) => {
    setLoading(true);
    axios
      .get("/user", { params: { id } })
      .then((res) => {
        setUser({
          ...res.data.user,
          groups: res.data.user.groups.map((g) => g._id),
          rates: res.data.rates,
        });
      })
      .catch((err) => {
        const { message } = errorHandler(err);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  const getAccountTypeOptions = () => {
    let options = [
      { label: "All", value: "all" },
      { label: "Employee", value: "employee" },
      { label: "Client", value: "client" },
    ];
    if (currentUser.accountType === "super")
      options = options.concat([
        { label: "Super ", value: "super" },
        { label: "Admin", value: "admin" },
      ]);
    else if (currentUser.accountType === "admin")
      options.push({ label: "Admin", value: "admin" });
    return options;
  };

  return (
    <Main>
      <CreateButton onClick={() => setUser({})}>+ Create User</CreateButton>
      <FolderContainer title="Users">
        <SearchBar
          style={{ width: "50%", margin: "auto" }}
          onSearch={(v) => setSearch(v)}
          onSearchBy={(v) => setSearchBy(v)}
          byOptions={["name", "email", "phone"]}
        />
        <Error>{error}</Error>

        <FilterBar>
          <SelectorSearch
            onSelect={(client) => setFilters((old) => ({ ...old, client }))}
            label="Clients"
            options={clients}
            width="20%"
            value={filters.client}
          />
          {currentUser.accountType !== "client" && (
            <SelectorSearch
              onSelect={(group) => setFilters((old) => ({ ...old, group }))}
              label="Groups"
              options={groups}
              width="20%"
            />
          )}
          {currentUser.accountType !== "client" && (
            <Selector
              onSelect={(accountType) =>
                setFilters((old) => ({ ...old, accountType }))
              }
              width="15%"
              label="Account Type"
              options={getAccountTypeOptions()}
            />
          )}
          <ToggleButton
            label="Show Disabled"
            onChange={() => setShowDisabled(!showDisabled)}
            value={showDisabled}
          />
          <ExportCSV data={format4CSV()} filename="users" />
        </FilterBar>
        <Table
          data={users}
          loading={loading}
          headers={headers}
          pagination={pagination}
          onClick={(row) => getSelectedUser(row._id)}
          onPage={(page) => setPagination((old) => ({ ...old, page }))}
          onLimit={(limit) => {
            console.log("limit: ", limit);
            setPagination((old) => ({ ...old, limit }));
          }}
          sortBy={sort.sort}
          sortDirection={sort.direction}
          onSort={(by, direction) => setSort({ sort: by, direction })}
        />
      </FolderContainer>
      <UserModal
        selected={user}
        onClose={() => {
          setUser(null);
          fetchUsers();
        }}
      />
    </Main>
  );
};
export default Users;
