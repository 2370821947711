import { Selector } from "globalComponents";
import React from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
import { ReactComponent as Up } from "../assets/upArrow.svg";
import { ReactComponent as Down } from "../assets/downArrow.svg";
const theme = getTheme();

const Main = styled.table`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => props.loading && 0.8};
  min-height: ${(props) => props.loading && "400px"};
  ${(props) =>
    props.loading &&
    `
   opacity: .8;
   min-height: 400px;
   pointer-events: none;
  `}
`;
const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  font-weight: 800;
  color: white;
  padding: 8px;
  border-bottom: 2px solid ${theme.BLACK};
  background: ${theme.MAIN_COLOR};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  min-height: 40px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`;

const Row = styled.tr`
  display: flex;
  padding: 6px;
  margin: 1px 0 2px 0;
  align-items: center;
  font-size: 12px;
  background-color: ${(props) =>
    props.odd ? theme.TABLE_ROW_ALT : theme.TABLE_ROW};
  font-weight: 800;
  cursor: ${(props) => props.clickable && "pointer"};
  :hover {
    background-color: ${theme.MAIN_COLOR};
  }
`;

const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  font-weight: 800;
  color: ${theme.HEADER_TEXT};
  padding: 8px;
  border-bottom: 2px solid ${theme.BLACK};
  background: ${theme.MAIN_COLOR};
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  min-height: 40px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  justify-content: space-between;
`;

const Header = styled.th`
  display: flex;
  flex-direction: row;
  height: 25px;
  width: ${(props) => props.width || "100%"};
  text-align: left;
  margin: 0px 4px;
  align-items: center;
  gap: 5px;
  cursor: ${(props) => props.sortBy && "pointer"};
  justify-content: ${(props) => (props.centered ? "center" : "left")};
`;
const Data = styled.td`
  width: ${(props) => props.width || "100%"};
  overflow-wrap: anywhere;
  margin: 0px 4px;
  text-align: ${(props) => (props.centered ? "center" : "left")};
`;

const Page = styled.div`
  color: ${theme.HEADER_TEXT};
  padding: 1px;
  font-size: 16px;
  cursor: pointer;
  color: ${(props) => props.disabled && theme.BUTTON_COLOR_GREY};
  pointer-events: ${(props) => props.disabled && "none"};
`;

const ArrowUp = styled(Up)`
  color: ${(props) =>
    props.active ? theme.MAIN_COLOR : theme.BUTTON_COLOR_GREY};
  width: 10px;
`;

const ArrowDown = styled(Down)`
  color: ${(props) =>
    props.active ? theme.MAIN_COLOR : theme.BUTTON_COLOR_GREY};
  width: 10px;
`;

const PageSelection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Total = styled.div``;

const Table = ({
  pagination,
  onPage,
  data,
  headers,
  onClick,
  onLimit,
  loading,
  sortBy,
  sortDirection,
  onSort,
}) => {
  const getPages = () => {
    const { page, total, limit } = pagination;
    const totalPages = Math.ceil(total / limit);
    const pageGroup = page / 6;
    var pages = [
      <Page key={1} onClick={(e) => onPage(1)} as="a" disabled={1 === page}>
        1
      </Page>,
    ];
    // add last page
    if (total > 1 && totalPages > 1)
      pages[totalPages] = (
        <Page
          key={totalPages}
          onClick={(e) => onPage(totalPages)}
          as="a"
          disabled={totalPages === page}
        >
          {totalPages}
        </Page>
      );

    //add in ... before page group
    if (pageGroup > 1)
      pages[1] = (
        <Page
          onClick={(e) => onPage(pageGroup * 6 - 1)}
          as="a"
          disabled={pageGroup * 6 - 1 === page}
        >
          ...
        </Page>
      );
    //add in ... after page group
    if (totalPages > 6 && totalPages > pageGroup * 6 + 5)
      pages[pageGroup * 6 + 6] = (
        <Page
          onClick={(e) => onPage(pageGroup * 6 + 6)}
          as="a"
          disabled={pageGroup * 6 + 6 === page}
        >
          ...
        </Page>
      );
    if (totalPages > 1)
      for (
        var i = pageGroup * 6;
        i < pageGroup * 6 + 6 && i < totalPages;
        i++
      ) {
        if (i > 1)
          pages[i] = (
            <Page
              key={i}
              onClick={(e) => onPage(i)}
              disabled={i.toString() === page}
            >
              {i}
            </Page>
          );
      }
    return pages;
  };
  return (
    <Main
      loading={loading}
      onClick={(e) => {
        loading && e.stopPropagation();
      }}
    >
      <HeaderRow>
        {headers &&
          headers.map((header) => (
            <Header
              onClick={() =>
                header.sortBy &&
                onSort(header.sortBy, sortDirection === 1 ? -1 : 1)
              }
              centered={header.centered}
              width={header.width}
              sortBy={header.sortBy}
            >
              {header.sortBy && sortBy === header.sortBy ? (
                sortDirection === -1 ? (
                  <ArrowUp
                    fill={
                      sortBy === header.sortBy && sortDirection === -1
                        ? theme.TABLE_ARROW_ACTIVE
                        : theme.TABLE_ARROW_INACTIVE
                    }
                  />
                ) : (
                  <ArrowDown
                    fill={
                      sortBy === header.sortBy && sortDirection === 1
                        ? theme.TABLE_ARROW_ACTIVE
                        : theme.TABLE_ARROW_INACTIVE
                    }
                  />
                )
              ) : null}
              {header.label}
            </Header>
          ))}
      </HeaderRow>
      {data.map((data, index) => (
        <Row
          odd={index % 2}
          clickable={!!onClick}
          onClick={() => onClick && onClick(data)}
        >
          {headers.map((header) => (
            <Data width={header.width} centered={header.centered}>
              {header.render(data)}
            </Data>
          ))}
        </Row>
      ))}
      {onPage && onLimit && (
        <FooterRow>
          <Selector
            width="100px"
            onSelect={onLimit}
            options={[
              { label: "20", value: 20 },
              { label: "50", value: 50 },
              { label: "100", value: 100 },
              { label: "200", value: 200 },
              { label: "All", value: 10000 },
            ]}
          />
          <PageSelection>
            <Page
              disabled={pagination.page === 1}
              onClick={() => onPage(pagination.page - 1)}
            >
              {"<Prev"}
            </Page>
            <Page
              disabled={
                pagination.page ===
                Math.ceil(pagination.total / pagination.limit)
              }
              onClick={() => onPage(pagination.page + 1)}
            >
              {"Next>"}
            </Page>
          </PageSelection>
          <Total>Total: {pagination.total}</Total>
        </FooterRow>
      )}
    </Main>
  );
};
export default Table;
