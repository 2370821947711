import axios from "axios";
import { Button, Column, Row } from "globalComponents";
import Notes from "globalComponents/Notes";
import { errorHandler } from "globalResources/util";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { getTheme } from "Theme";
import ClientEdit from "./components/ClientEdit";
import EmployeeList from "./components/EmployeeList";
import ManagerList from "./components/ManagerList";
const theme = getTheme();

const Main = styled.div`
	display: flex;
	flex-direction: row;
	margin: auto;
	@media (min-width: 800px) {
		padding: 0 5vw;
	}
`;

const BackButton = styled(Button)`
	position: absolute;
	width: 150px;
	right: 5vw;
	height: 40px;
`;

const InfoContainer = styled.div`
	width: 400px;
	margin-right: auto;
	//background-color: ${theme.MANILLA};
	border-radius: 15px;
	padding: 10px;
`;

const ItemListContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 50px 0px;
`;
const SectionTitle = styled.h3`
	margin-bottom: 0;
`;
const Title = styled.h1`
	margin-bottom: 4px;
	display: flex;
	flex-direction: row;
`;
const Description = styled.p``;
const AddressBox = styled.div`
	border-top: 2px solid ${theme.BORDER};
	border-bottom: 2px solid ${theme.BORDER};
	padding: 10px;
`;
const Info = styled.div`
	font-size: 16px;
	display: flex;
	font-weight: 600;
`;

const InfoValue = styled.div`
	font-size: 14px;
	font-weight: 500;
	margin-left: auto;
`;

const Stat = styled.div`
	font-size: 18px;
	font-weight: 900;
`;
const InfoTitle = styled.h3`
	border-bottom: 2px solid ${theme.BORDER};
	border-left: 0px;
	border-right: 0px;
`;

const EditButton = styled.button`
	display: flex;
	border: none;
	background: none;
	color: ${theme.BUTTON_COLOR};
	border-radius: 10px;
	padding: 2px 10px;
	font-size: 16px;
	font-weight: 700;
	cursor: pointer;
	margin-left: auto;
`;

const ClientDetails = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const [details, setDetails] = useState(null);
	const [loading, setLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const history = useHistory();

	const fetchDetails = () => {
		setLoading(true);
		axios.get("/client/team", { params: { id } }).then(res => {
			setDetails(res.data);
			setLoading(false);
		});
	};
	useEffect(() => {
		fetchDetails();
	}, [id]);

	const addManager = manager => {
		let temp = details.client;

		temp.managers.push(manager);
		axios
			.patch("/client", { ...temp, id: temp._id })
			.then(res => setDetails(res.data))
			.catch(async error => {
				const { message } = await errorHandler(error);
			});
	};

	const removeManager = manager => {
		let temp = details.client;

		temp.managers = temp.managers.filter(e => e._id !== manager._id);

		axios
			.patch("/client", { ...temp, id: temp._id })
			.then(res => setDetails(res.data))
			.catch(async error => {
				const { message } = await errorHandler(error);
			});
	};

	if (loading) return null;
	if (!details || !details.client) return null;
	const client = details.client;
	return (
		<Main>
			<BackButton
				color={theme.BUTTON_COLOR_GREY}
				onClick={() => history.push("/clients")}
			>
				Back to Clients
			</BackButton>
			<InfoContainer>
				<Title>{client.name}</Title>
				<Description>{client.description}</Description>
				<Row>
					<SectionTitle>Details</SectionTitle>
					<EditButton onClick={() => setEdit(true)}>Edit</EditButton>
				</Row>
				<AddressBox>
					<Row>
						<Column style={{ textAlign: "center" }}>
							<Stat>{details.users.length}</Stat>
							<Info> Employee(s)</Info>
						</Column>
						<Column style={{ marginLeft: "auto", textAlign: "center" }}>
							<Stat>{details.managers.length}</Stat>
							<Info>Manager(s)</Info>
						</Column>
					</Row>
				</AddressBox>
				<InfoTitle>Address</InfoTitle>
				<Info>{client.address}</Info>
				<Row>
					<Info>{client.city}, </Info>
					<Info style={{ margin: "0px 5px" }}>{client.state} </Info>
					<Info> {client.zipcode}</Info>
				</Row>
				<InfoTitle>Report Settings</InfoTitle>
				<Info>
					Timezone:
					<InfoValue>{client.timezone}</InfoValue>
				</Info>
				<Info>
					Invoice Day:
					<InfoValue>
						{client.reportDay === "none"
							? "Automatic Reports Disabled"
							: client.reportDay}
					</InfoValue>
				</Info>
				<Info style={{ marginBottom: "20px" }}>
					Subtracts Lunch?:
					<InfoValue> {client.subtractLunch ? "Yes" : "No"}</InfoValue>
				</Info>
				<Notes notes={client.notes} type="client" id={client._id} />
			</InfoContainer>
			<ItemListContainer>
				<EmployeeList
					employees={details.users}
					client={client}
					updateDetails={fetchDetails}
				/>
				<ManagerList
					managers={details.managers}
					client={client}
					updateDetails={fetchDetails}
				/>
			</ItemListContainer>
			<ClientEdit
				open={edit}
				client={client}
				onClose={client => {
					setEdit(false);
					console.log(client);
					if (client) setDetails(old => ({ ...old, client }));
				}}
			/>
		</Main>
	);
};
export default ClientDetails;
