import React from "react";
import {
  Button,
  Form,
  Message,
  Modal,
  Header,
  Grid,
  List,
  Icon,
  Popup,
} from "semantic-ui-react";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import cookie from "js-cookie";
import Theme from "../../../Theme.js";
import { Confirm } from "../../../globalComponents";
import moment from "moment-timezone";
import NotesModal from "globalComponents/NotesModal.js";

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});
class CreateGroup extends React.Component {
  state = {
    users: [],
    headers: [],
    create: false,
    searchBy: "name",
    search: "",
    permissions: [],
    selectedPermissions: [],
  };
  fetchUsers = () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
      "token"
    )}`;
    this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/users`, {
        params: { search: this.state.search, searchBy: this.state.searchBy },
      })
      .then(async (res) => {
        if (res.data.users.length < 1) {
          this.setState({
            users: [],
          });
        } else {
          this.setState({
            users: res.data.users,
            loading: false,
          });
        }
      })
      .catch((err) => this.setState({ loading: false, error: "error" }));
  };
  fetchPermissions = () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
      "token"
    )}`;
    this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/permissions`, {
        params: { full: true },
      })
      .then(async (res) => {
        if (res.data.permissions.length < 1) {
          this.setState({ permissions: [] });
        } else {
          this.setState({ permissions: res.data.permissions });
        }
      })
      .catch((err) => {
        this.setState({ loading: false, error: "error" });
      });
  };
  componentDidMount() {
    this.fetchUsers();
    this.fetchPermissions();
  }
  render() {
    const { searchBy, users, permissions, confirm, showMore } = this.state;
    const { selected, onClose } = this.props;
    if (!selected) return null;
    return (
      <Formik
        initialValues={{
          name: selected._id ? selected.name : "",
          description: selected._id ? selected.description : "",
          users: selected._id ? selected.users : [],
          team: selected._id ? selected.team : [],
          permissions: selected._id ? selected.permissions : [],
          notifyBilled: selected._id ? selected.notifyBilled : false,
          notifyReview: selected._id ? selected.notifyReview : false,
        }}
        enableReinitialize={true}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          axios[selected._id ? "patch" : "post"](
            `${process.env.REACT_APP_API_URL}/group`,
            {
              id: selected._id,
              ...values,
            }
          )
            .then((res) => {
              actions.setSubmitting(false);
              actions.resetForm();
              this.props.onClose();
            })
            .catch((err) => {
              actions.setFieldError("general", "Sever Error Occured");
              console.error(err);
            });
          actions.setSubmitting(false);
        }}
        render={(props) => (
          <Modal
            open={true}
            centered
            onClose={() => {
              if (props.dirty) this.setState({ confirm: true });
              else onClose();
            }}
            closeIcon
            style={{
              textAlign: "center",
              backgroundColor: "white",
              padding: "1vh",
              maxWidth: 700,
            }}
          >
            <Header as="h1">
              {" "}
              {selected.id ? `Updating ${selected.name}` : "Creating Group"}
            </Header>
            <Header as="h3">
              {" "}
              {selected && selected.default
                ? "This is a default group and only users can be edited"
                : null}
            </Header>
            <Form onSubmit={props.handleSubmit} size="large">
              <Message style={{ backgroundColor: Theme.PAGE_BACKGROUND }}>
                <Form.Input
                  fluid
                  error={
                    props.errors.name && props.touched.name
                      ? props.errors.name
                      : false
                  }
                  name="name"
                  value={props.values.name}
                  onChange={(event, { name, value }) => {
                    props.setFieldValue(name, value);
                  }}
                  style={{ color: "blue" }}
                  icon="user"
                  iconPosition="left"
                  placeholder="Name"
                  onBlur={() => props.setFieldTouched("name")}
                />
                <Form.Input
                  fluid
                  error={
                    props.errors.description && props.touched.description
                      ? props.errors.description
                      : false
                  }
                  style={{ color: "blue" }}
                  icon="user"
                  name="description"
                  value={props.values.description}
                  onChange={(event, { name, value }) => {
                    props.setFieldValue(name, value);
                  }}
                  iconPosition="left"
                  placeholder="Description"
                  onBlur={() => props.setFieldTouched("description")}
                />

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Popup
                    content={
                      <div>
                        <Header as="h5">
                          User receivs an email whenever a report is approved by
                          a client and waiting to be set to paid.
                        </Header>
                      </div>
                    }
                    position="top"
                    trigger={
                      <Form.Radio
                        style={{
                          margin: "1em auto 1em auto",
                          backgroundColor: "white",
                          padding: "1em",
                        }}
                        label="Receive Billed Notification"
                        name="notifyBilled"
                        checked={props.values.notifyBilled}
                        value={props.values.notifyBilled}
                        toggle
                        onChange={(event, { name, value }) => {
                          if (!value) {
                            props.setFieldValue("notifyBilled", true);
                          } else props.setFieldValue("notifyBilled", false);
                        }}
                      />
                    }
                  />
                  <Popup
                    content={
                      <div>
                        <Header as="h5">
                          User receivs an email whenever a report is sent to you
                          by a client for approval
                        </Header>
                      </div>
                    }
                    position="top"
                    trigger={
                      <Form.Radio
                        style={{
                          margin: "1em auto 1em auto",
                          backgroundColor: "white",
                          padding: "1em",
                        }}
                        label="Review Notifications"
                        name="notifyReview"
                        checked={props.values.notifyReview}
                        value={props.values.notifyReview}
                        toggle
                        onChange={(event, { name, value }) => {
                          if (!value) {
                            props.setFieldValue("notifyReview", true);
                          } else props.setFieldValue("notifyReview", false);
                        }}
                      />
                    }
                  />
                </div>
                <Grid columns={2} style={{ margin: ".5vh 0 .5vh 0" }}>
                  <Grid.Row>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        margin: "auto ",
                      }}
                    >
                      <Form.Dropdown
                        onChange={(event, { name, value }) => {
                          this.setState({ searchBy: value }, this.fetchUsers);
                        }}
                        selection
                        value={this.state.searchBy}
                        defaultValue="name"
                        options={[
                          { text: "Name", value: "name" },
                          { text: "Email", value: "email" },
                          { text: "Phone Number", value: "number" },
                        ]}
                        placeholder="Search By"
                      />
                      <Form.Input
                        onChange={(event, { name, value }) => {
                          this.setState({ search: value }, this.fetchUsers);
                        }}
                        value={this.state.search}
                        style={{ color: "blue", marginLeft: 5 }}
                        icon="search"
                        iconPosition="left"
                        placeholder={`Search by ${searchBy}`}
                        onBlur={this.fetchUsers}
                      />
                    </div>
                    <Grid.Column
                      style={{
                        border: "2px solid #333333",
                        borderRadius: 10,
                        paddingTop: "1vh",
                        backgroundColor: "white",
                      }}
                    >
                      <Header as="h4">Users</Header>
                      <List
                        divided
                        style={{
                          height: "20vh",
                          overflowY: "scroll",
                        }}
                      >
                        {users.map((user, index) => {
                          if (!user || props.values.users.includes(user._id))
                            return null;
                          return (
                            <Popup
                              content={
                                <div>
                                  <Header as="h5">
                                    Email: {user.email}
                                    <br />
                                    Phone: {user.phone}
                                  </Header>
                                </div>
                              }
                              trigger={
                                <List.Item key={index}>
                                  <List.Content>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Header as="h5">
                                        {searchBy === "name"
                                          ? user.firstName + " " + user.lastName
                                          : user[searchBy]}
                                      </Header>
                                      <Button
                                        onClick={() => {
                                          var temp = props.values.users;
                                          temp.push(user._id);
                                          props.setFieldValue("users", temp);
                                          temp = props.values.team;
                                          temp.push(user);
                                          props.setFieldValue("team", temp);
                                        }}
                                        type="button"
                                        icon
                                      >
                                        <Icon name="add" />
                                      </Button>
                                    </div>
                                  </List.Content>
                                </List.Item>
                              }
                            />
                          );
                        })}
                      </List>
                    </Grid.Column>
                    <Grid.Column
                      style={{
                        border: "2px solid #333333",
                        borderRadius: 10,
                        paddingTop: "1vh",
                        backgroundColor: "white",
                      }}
                    >
                      <Header as="h4">Selected Users</Header>
                      {console.log("team: ", props.values.team)}
                      <List
                        divided
                        style={{
                          height: "20vh",
                          overflowY: "scroll",
                        }}
                      >
                        {props.values.team &&
                          props.values.team.map((user, index) => {
                            if (!user) return null;
                            else
                              return (
                                <Popup
                                  content={
                                    <div>
                                      <Header as="h5">
                                        Email: {user.email}
                                        <br />
                                        Phone: {user.phone}
                                      </Header>
                                    </div>
                                  }
                                  trigger={
                                    <List.Item key={index}>
                                      <List.Content>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Header as="h5">
                                            {searchBy === "name"
                                              ? user.firstName +
                                                " " +
                                                user.lastName
                                              : user[searchBy]}
                                          </Header>
                                          <Button
                                            onClick={() => {
                                              props.setFieldValue(
                                                "team",
                                                props.values.team.filter(
                                                  (cur) =>
                                                    cur && cur._id !== user._id
                                                )
                                              );
                                              props.setFieldValue(
                                                "users",
                                                props.values.users.filter(
                                                  (cur) => cur !== user._id
                                                )
                                              );
                                            }}
                                            type="button"
                                            icon
                                          >
                                            <Icon name="delete" />
                                          </Button>
                                        </div>
                                      </List.Content>
                                    </List.Item>
                                  }
                                />
                              );
                          })}
                      </List>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row centered>
                    <Form.Input
                      onChange={(event, { name, value }) => {
                        this.setState({ permission: value });
                      }}
                      style={{ color: "blue", margin: "auto" }}
                      icon="search"
                      iconPosition="left"
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column
                      style={{
                        border: "2px solid #333333",
                        borderRadius: 10,
                        paddingTop: "1vh",
                        backgroundColor: "white",
                      }}
                    >
                      <Header as="h4">Permissions</Header>
                      <List
                        divided
                        style={{
                          height: "20vh",
                          overflowY: "scroll",
                        }}
                      >
                        {permissions
                          .filter(
                            (perm) =>
                              !this.state.permission ||
                              perm.name.includes(this.state.permission)
                          )
                          .map((permission, index) => {
                            if (
                              this.filterPermission(
                                permission,
                                props.values.permissions
                              )
                            )
                              return null;
                            else
                              return (
                                <Popup
                                  content={permission.description}
                                  trigger={
                                    <List.Item key={index}>
                                      <List.Content>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Header as="h5">
                                            {permission.name}
                                          </Header>
                                          <Button
                                            onClick={() =>
                                              props.setFieldValue(
                                                "permissions",
                                                props.values.permissions.concat(
                                                  permission
                                                )
                                              )
                                            }
                                            type="button"
                                            icon
                                          >
                                            <Icon name="add" />
                                          </Button>
                                        </div>
                                      </List.Content>
                                    </List.Item>
                                  }
                                />
                              );
                          })}
                      </List>
                    </Grid.Column>
                    <Grid.Column
                      style={{
                        border: "2px solid #333333",
                        borderRadius: 10,
                        paddingTop: "1vh",
                        backgroundColor: "white",
                      }}
                    >
                      <Header as="h4">Selected Permissions</Header>
                      <List
                        divided
                        style={{
                          height: "20vh",
                          overflowY: "scroll",
                        }}
                      >
                        {permissions.map((permission, index) => {
                          if (
                            !this.filterPermission(
                              permission,
                              props.values.permissions
                            )
                          )
                            return null;
                          else
                            return (
                              <Popup
                                content={permission.description}
                                trigger={
                                  <List.Item key={index}>
                                    <List.Content>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Header as="h5">
                                          {permission.name}
                                        </Header>
                                        <Button
                                          onClick={() => {
                                            props.setFieldValue(
                                              "permissions",
                                              props.values.permissions.filter(
                                                (curr) =>
                                                  curr.id !== permission.id
                                              )
                                            );
                                          }}
                                          type="button"
                                          icon
                                        >
                                          <Icon name="delete" />
                                        </Button>
                                      </div>
                                    </List.Content>
                                  </List.Item>
                                }
                              />
                            );
                        })}
                      </List>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {props.errors.general && (
                  <div
                    style={{
                      color: "blue",
                      margin: "-1vh auto .5vh auto",
                      textAlign: "center",
                    }}
                  >
                    {props.errors.general}
                  </div>
                )}
                {selected.notes && (
                  <NotesModal
                    notes={selected.notes}
                    type="group"
                    id={selected._id}
                  />
                )}
                <Button
                  type="submit"
                  fluid
                  disabled={props.isSubmitting}
                  color="blue"
                  size="large"
                  style={{ marginTop: "15px" }}
                >
                  {selected.create ? "Create Group" : "Edit Group"}
                </Button>
              </Message>
            </Form>
            <Confirm
              message="Exit without saving changes?"
              open={confirm}
              onConfirm={() => this.setState({ confirm: false }, onClose)}
              onDeny={() => this.setState({ confirm: false })}
            />
          </Modal>
        )}
      />
    );
  }
}

export default CreateGroup;
