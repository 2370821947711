import moment from "moment-timezone";

function calculateTimes(group, client) {
  return new Promise((resolve, reject) => {
    let totals = {
      hours: 0,
      paid: 0,
      billed: 0,
      overtimePaid: 0,
      overtimeBilled: 0,
      overtimeHours: 0,
      profit: 0,
      total: 0,
    };
    if (group.times) {
      group.times.sort((a, b) => (a.start > b.start ? 1 : -1));
      group.times.forEach((time) => {
        let diff =
          Math.round(
            (moment
              .duration(moment(time.end).diff(moment(time.start)))
              .asHours() -
              (client.subtractLunch && !group.user.overrideLunch ? 0.5 : 0)) *
              100
          ) / 100;

        if (totals.hours > 40) {
          totals.hours += diff;
          totals.overtimePaid += (diff * time.overtimePayRate) / 100;
          totals.overtimeBilled += (diff * time.overtimeBillRate) / 100;
        } else if (diff + totals.hours > 40) {
          totals.hours += diff;
          let overtime = totals.hours - 40;
          totals.paid += ((diff - overtime) * time.payRate) / 100;
          totals.billed += ((diff - overtime) * time.billRate) / 100;
          totals.overtimePaid += (overtime * time.overtimePayRate) / 100;
          totals.overtimeBilled += (overtime * time.overtimeBillRate) / 100;
        } else {
          totals.hours += diff;
          totals.paid += (diff * time.payRate) / 100;
          totals.billed += (diff * time.billRate) / 100;
        }
      });
    }
    totals.total = totals.billed + totals.overtimeBilled;
    totals.profit = totals.total - (totals.paid + totals.overtimePaid);
    totals.overtimeHours = totals.hours > 40 ? totals.hours - 40 : 0;
    group.totals = totals;
    resolve(group);
  });
}

function groupedTotals(groups, client) {
  return new Promise((resolve, reject) => {
    groups.forEach((group) => calculateTimes(group, client));
    resolve(groups);
  });
}

function getTotals(groups) {
  let totals = {
    hours: 0,
    paid: 0,
    overtimePaid: 0,
    billed: 0,
    overtimeBilled: 0,
    profit: 0,
    total: 0,
    overtimeHours: 0,
  };
  return new Promise((resolve, reject) => {
    groups.forEach((group) => {
      totals.hours += group.totals.hours;
      totals.paid += group.totals.paid;
      totals.overtimePaid += group.totals.overtimePaid;
      totals.billed += group.totals.billed;
      totals.overtimeBilled += group.totals.overtimeBilled;
      totals.profit += group.totals.profit;
      totals.total += group.totals.total;
      totals.overtimeHours += group.totals.overtimeHours;
    });
    resolve(totals);
  });
}

export async function calculateReportTotals(reports, onDone) {
  let temp = Object.assign({}, reports);
  temp.timesheets.sort((a, b) => {
    if (a.user.firstName.toLowerCase() === b.user.firstName.toLowerCase()) {
      if (a.user.lastName.toLowerCase() === b.user.lastName.toLowerCase())
        return a.user._id > b.user._id ? 1 : -1;
      return a.user.lastName.toLowerCase() > b.user.lastName.toLowerCase()
        ? 1
        : -1;
    } else
      return a.user.firstName.toLowerCase() > b.user.firstName.toLowerCase()
        ? 1
        : -1;
  });
  let results = await groupedTotals(temp.timesheets, temp.client);
  getTotals(results).then((totals) =>
    onDone({
      report: reports,
      groups: results,
      totals: totals,
    })
  );
}
